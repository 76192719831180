import {
  BaseCommentDto,
  BehaviourOptionsDto,
  DevelopmentOptionsDto,
  FormQuestionDto,
  FormQuestionSequenceMapDto,
  GoalReviewOptionsDto,
} from "../dtos/forms";
import {
  QuestionType,
  QuestionAnswerValue,
  ActorQuestionText,
  ActorRestriction,
  MultipleChoiceQuestionAnswerValue,
  FormType,
} from ".";
import ValidationResult, { ValidationError } from "./ValidationResult";
import GoalReviewStatusCommentValidationResult from "./GoalReviewStatusCommentValidationResult";
import ValidationSettings from "./ValidationSettings";
import BehaviourQuestionAnswerValue from "./BehaviourQuestionAnswerValue";
import DevelopmentQuestionAnswerValue from "./DevelopmentQuestionAnswerValue";
import { EditableTask } from "../tasks/EditableTasks";
import GoalReviewQuestionAnswerValue from "./GoalReviewQuestionAnswerValue";
import MultipleChoiceOptionNumericId from "./MultipleChoiceOptions";
import TaskManagementConfigDto from "../dtos/forms/TaskManagementConfigDto";
import taskRestrictionHelper from "../../helpers/taskRestrictionHelper";
import AdvancedTaskDto from "../dtos/forms/AdvancedTaskDto";
import { advancedTaskHelper } from "../../helpers";

export class FormQuestion implements FormQuestionDto {
  constructor(dto: FormQuestionDto) {
    /** This is a string rather than numeric to facilitate advanced questions
     * like behvaiours and goals having an id like "GOAL_123", so they don't have
     * to have a numeric QuestionId in the FormQuestions TT db table  */
    this.questionId = dto.questionId;
    this.formId = dto.formId;
    this.questionText = dto.questionText;
    this.questionType = dto.questionType;
    this.commentsEnabled = dto.commentsEnabled;
    this.sequenceMap = dto.sequenceMap;
    this.multiChoiceOptions = dto.multiChoiceOptions;
    this.behaviourOptions = dto.behaviourOptions;
    this.behaviourOptionsArray = dto.behaviourOptionsArray;
    this.developmentOptions = dto.developmentOptions;
    this.goalReviewOptions = dto.goalReviewOptions;
    this.validation = dto.validation;
    this.actorRestriction = dto.actorRestriction;
    this.placeholderText = dto.placeholderText;
    this.enforcedCommentIsOptional = dto.enforcedCommentIsOptional;
    this.enforcedCommentQuestionText = dto.enforcedCommentQuestionText;
    this.helpTextTrigger = dto.helpTextTrigger;
    this.helpText = dto.helpText;
    this.allowEditByBothInCollabDoc = dto.allowEditByBothInCollabDoc;
    this.taskManagementConfig = dto.taskManagementConfig;
  }

  questionId: string;
  /** The ClientFormId */
  formId: number;
  questionText: ActorQuestionText;
  questionType: QuestionType;
  commentsEnabled: boolean;
  sequenceMap: FormQuestionSequenceMapDto;
  multiChoiceOptions?: MultipleChoiceOptionNumericId[] | null | undefined;
  behaviourOptions?: BehaviourOptionsDto | null | undefined;
  behaviourOptionsArray?: BehaviourOptionsDto[] | null | undefined;
  developmentOptions?: DevelopmentOptionsDto | null | undefined;
  goalReviewOptions?: GoalReviewOptionsDto | null | undefined;
  validation: ValidationSettings;
  actorRestriction: ActorRestriction;
  placeholderText: string | null;
  enforcedCommentIsOptional?: boolean | null;
  /** If a comment is required (based on enforcedCommentIsOptional), this is the text to display above the textarea */
  enforcedCommentQuestionText: string | null;
  helpTextTrigger: string | null;
  helpText: string | null;
  allowEditByBothInCollabDoc: boolean;
  taskManagementConfig?: TaskManagementConfigDto | null | undefined;

  /** Is this a question which we expect to have the `multiChoiceOptions` property filled with a value */
  isMultiChoiceQuestion(): boolean {
    return (
      this.questionType === "RADIOLIST" ||
      this.questionType === "CHECKLIST" ||
      this.questionType === "DROPDOWNLIST" ||
      this.questionType === "SLIDER"
    );
  }

  /** Whether or not the selected value should be a single item, or an array */
  multipleValuesAllowed(): boolean {
    return this.questionType === "CHECKLIST";
  }

  /** Whether or not the next question to be displayed after this one, depends on the answer given to this question */
  nextQuestionIsConditional(): boolean {
    return (
      this.sequenceMap.options !== undefined &&
      this.sequenceMap.options !== null &&
      this.sequenceMap.options.length > 0
    );
  }

  /** Whether or not this is the last question in the form. Used to calculate whether or not to display the Submit button in journeys */
  isLastQuestionInForm(): boolean {
    return (
      !this.sequenceMap.defaultNextQuestionId &&
      (!this.sequenceMap.options || this.sequenceMap.options.length === 0)
    );
  }

  hasEnforcedCommentQuestionText(): boolean {
    return (
      this.enforcedCommentQuestionText !== null &&
      this.enforcedCommentQuestionText !== undefined &&
      this.enforcedCommentQuestionText.length > 0
    );
  }

  shouldEnforceComment(): boolean {
    return (
      this.hasEnforcedCommentQuestionText() &&
      this.enforcedCommentIsOptional === false
    );
  }

  isGoalReviewQuestion(): boolean {
    return this.questionType === "GOAL-REVIEW-STATUS-COMMENT";
  }

  /** Return true if the user is allowed to edit this question, based on the actor restrictions and whether the form is readonly or not,
   *  or if the question is allowed to be edited by both parties as well as it not being in prep mode*/
  userCanAnswer(
    loggedInUserId: number,
    formSubjectUserId: number,
    isReadOnly?: boolean | null | undefined,
    isInPrepMode?: boolean | null | undefined
  ): boolean {
    if (isReadOnly) return false;
    if (this.allowEditByBothInCollabDoc && !isInPrepMode) return true;
    if (!this.actorRestriction || this.actorRestriction === "NONE") return true;
    if (this.actorRestriction === "FULLY-READONLY") return false;
    if (
      this.actorRestriction === "EMPLOYEE" &&
      loggedInUserId === formSubjectUserId
    )
      return true;
    if (
      this.actorRestriction === "MANAGER" &&
      loggedInUserId !== formSubjectUserId
    )
      return true;
    return false;
  }

  /** Whether or not this question passes the validation checks defined in the ValidationSettings */
  validate(
    answer: QuestionAnswerValue | null,
    tasks: EditableTask<string>[] | null,
    taskManagementConfig: TaskManagementConfigDto | null,
    comments: BaseCommentDto[],
    loggedInUserId: number,
    formSubjectUserId: number,
    formType: FormType
  ): ValidationResult {
    if (
      !this.validation.required ||
      (!this.userCanAnswer(loggedInUserId, formSubjectUserId) &&
        this.questionType !== "GOAL-REVIEW-STATUS-COMMENT")
    ) {
      return new ValidationResult(true);
    }

    // Bespoke validation for behaviour question type
    if (this.questionType === "BEHAVIOUR") {
      return this.validateBehaviourQuestion(answer, comments);
    }

    // Bespoke validation for behaviour question type
    if (this.questionType === "LEARNING-AND-DEVELOPMENT") {
      return this.validateDevelopmentQuestion(answer);
    }

    // Bespoke validation for add task question type
    if (this.questionType === "ADDTASK") {
      if (this.validation.toDoType && this.validation.toDoType === "GOAL") {
        return this.validateGoalSettingQuestion(tasks);
      }
      return this.validateTaskQuestion(tasks);
    }

    // Bespoke validation for goal setting question type
    if (this.questionType === "GOAL-SETTING") {
      return this.validateGoalSettingQuestion(tasks);
    }

    // Bespoke validation for goal review question type
    if (this.questionType === "GOAL-REVIEW-STATUS-COMMENT") {
      return this.validateGoalReviewStatusCommentQuestion(answer, comments);
    }

    // Bespoke validation for advanced task management question type
    if (this.questionType === "ADVANCED-TASK-MANAGEMENT") {
      return this.validateAdvancedTaskManagementQuestion(
        taskManagementConfig,
        formType
      );
    }

    // Check to see if there are any options that allow custom text to be entered
    const optsThatAllowCustomText = this.multiChoiceOptions?.filter(
      (x) => x.allowCustomText
    );
    if (optsThatAllowCustomText && optsThatAllowCustomText?.length > 0) {
      // If there are then we try and get the answers, if available, looping over the options
      const answers = answer as MultipleChoiceQuestionAnswerValue[];
      if (answer != null) {
        let showValidationWarning = false;
        optsThatAllowCustomText.forEach((opt) => {
          // Find the answer for the option and check it's content... if undefined or empty then we need to
          // trigger the validation warning if it hasn't been tripped yet
          const givenAnswer = answers.find((x) => x.optionId === opt.optionId);
          if (
            givenAnswer &&
            (givenAnswer.customText === undefined ||
              givenAnswer.customText?.length === 0) &&
            !showValidationWarning
          ) {
            showValidationWarning = true;
          }
        });

        if (showValidationWarning) {
          return new ValidationResult(false, [
            {
              errorType: "CUSTOM-TEXT-REQUIRED",
            },
          ]);
        }
      }
    }

    // If not multiple choice...
    let isValid = false;
    if (!this.multipleValuesAllowed()) {
      if (answer !== null) {
        // Check the answer length and if its greater than 0 we can say its valid
        const answerLength = answer.toString().trim().length;
        isValid = answerLength > 0;
        return new ValidationResult(isValid, [{ errorType: "REQUIRED" }]);
      } else {
        // If answer is null then no answer has been given
        return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
      }
    }

    // Check the min/max validations pass
    const answerCount = Array.isArray(answer)
      ? (answer as MultipleChoiceQuestionAnswerValue[]).length
      : answer != null
      ? 1
      : 0;

    if (this.validation.min !== null && this.validation.max !== null) {
      isValid =
        answerCount >= this.validation.min &&
        answerCount <= this.validation.max;
      return new ValidationResult(isValid, [
        {
          errorType: "MIN-AND-MAX",
          min: this.validation.min,
          max: this.validation.max,
        },
      ]);
    } else if (this.validation.min !== null) {
      isValid = answerCount >= this.validation.min;
      return new ValidationResult(isValid, [
        { errorType: "MIN", min: this.validation.min },
      ]);
    } else if (this.validation.max !== null) {
      isValid = answerCount <= this.validation.max;
      return new ValidationResult(isValid, [
        { errorType: "MAX", max: this.validation.max },
      ]);
    }

    // Shouldn't get here in theory
    return new ValidationResult(false);
  }

  private validateBehaviourQuestion = (
    answer: QuestionAnswerValue | null,
    comments: BaseCommentDto[]
  ): ValidationResult => {
    // Check an answer exists, which should be an array
    if (
      answer === null ||
      !Array.isArray(answer) ||
      (!this.behaviourOptions && !this.behaviourOptionsArray)
    ) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // Convert the type to the expected array type
    var behaviourAnswers = answer as BehaviourQuestionAnswerValue[];
    let expectedAnswerCount: number;
    let expectedCommentCount: number;

    if (this.behaviourOptions) {
      // Attribute-based behaviour question
      expectedAnswerCount = this.behaviourOptions.attributes.length;
      expectedCommentCount = 1;
    } else if (
      this.behaviourOptionsArray &&
      this.behaviourOptionsArray.length > 0
    ) {
      // Multiple behaviours in a single question, no attributes
      expectedAnswerCount = this.behaviourOptionsArray.length;
      expectedCommentCount = this.behaviourOptionsArray.length;
    } else {
      // Unexpected scenario - value added to prevent passing validation
      expectedAnswerCount = -1;
      expectedCommentCount = -1;
    }

    // Get the answer count
    const answerCount = behaviourAnswers.filter(
      (x) => x.selectedScaleValue !== null
    ).length;
    const answerIsValid = answerCount === expectedAnswerCount;

    let commentsAreValid: boolean = true;

    // If enforced comments are required, check there's one per behaviour for this question
    if (this.shouldEnforceComment()) {
      if (!comments || comments.length === 0) {
        commentsAreValid = false;
      } else {
        // Get the enforced comments (those that have a behaviourId) and get just the valid ones
        const validEnforcedComments = comments.filter(
          (x) => x.behaviourId !== null && this.validateBehaviourComment(x)
        );

        // Get the unique behaviour ids for those valid comments
        const behaviourIdsWithValidComments = new Set(
          validEnforcedComments.map((x) => x.behaviourId)
        );

        // Return true if the count of valid comments matches the count of expected comments
        commentsAreValid =
          behaviourIdsWithValidComments.size === expectedCommentCount;
      }
    }

    // Return valid only if both answers and enforced comments are valid
    return new ValidationResult(answerIsValid && commentsAreValid, [
      { errorType: "REQUIRED" },
    ]);
  };

  private validateDevelopmentQuestion = (
    answer: QuestionAnswerValue | null
  ): ValidationResult => {
    // Check an answer exists, which should be an array
    if (answer === null || !Array.isArray(answer) || !this.developmentOptions) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // Convert the type to the expected array type
    var developmentAnswers = answer as DevelopmentQuestionAnswerValue[];

    // If the question is mandatory, ensure at least one item has been selected
    const selectedItemCount = developmentAnswers
      ? developmentAnswers.length
      : 0;
    if (this.validation.required && selectedItemCount === 0) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // If we get here, it's valid
    return new ValidationResult(true, []);
  };

  private validateTaskQuestion = (
    tasks: EditableTask<string>[] | null
  ): ValidationResult => {
    const taskCount = tasks ? tasks.length : 0;

    let isValid = true;
    let validationErrors: ValidationError[] = [];

    if (this.validation.min && this.validation.max) {
      if (taskCount < this.validation.min || taskCount > this.validation.max) {
        isValid = false;
        validationErrors.push({ errorType: "MIN-AND-MAX" });
      }
    } else if (this.validation.min && taskCount < this.validation.min) {
      isValid = false;
      validationErrors.push({ errorType: "MIN" });
    } else if (this.validation.max && taskCount > this.validation.max) {
      isValid = false;
      validationErrors.push({ errorType: "MAX" });
    } else if (this.validation.required && taskCount === 0) {
      isValid = false;
      validationErrors.push({ errorType: "REQUIRED" });
    }

    return new ValidationResult(isValid, validationErrors);
  };

  private validateGoalSettingQuestion = (
    tasks: EditableTask<string>[] | null
  ): ValidationResult => {
    const taskCount = tasks ? tasks.length : 0;

    let isValid = true;
    let validationErrors: ValidationError[] = [];

    if (this.validation.min && this.validation.max) {
      if (taskCount < this.validation.min || taskCount > this.validation.max) {
        isValid = false;
        validationErrors.push({
          errorType: "MIN-AND-MAX",
          subType: "Goals",
          min: this.validation.min,
          max: this.validation.max,
        });
      }
    } else if (this.validation.min && taskCount < this.validation.min) {
      isValid = false;
      validationErrors.push({
        errorType: "MIN",
        subType: "Goals",
        min: this.validation.min,
      });
    } else if (this.validation.max && taskCount > this.validation.max) {
      isValid = false;
      validationErrors.push({
        errorType: "MAX",
        subType: "Goals",
        max: this.validation.max,
      });
    } else if (this.validation.required && taskCount === 0) {
      isValid = false;
      validationErrors.push({ errorType: "REQUIRED" });
    }

    return new ValidationResult(isValid, validationErrors);
  };

  private validateGoalReviewStatusCommentQuestion = (
    answer: QuestionAnswerValue | null,
    enforcedComments: BaseCommentDto[]
  ): GoalReviewStatusCommentValidationResult => {
    // Check the necessary config exists, if not, fail validation as the form setup is invalid anyway
    if (
      !this.goalReviewOptions ||
      !this.goalReviewOptions.goals ||
      this.goalReviewOptions.goals.length === 0 ||
      !this.goalReviewOptions.goalStatusOptions ||
      this.goalReviewOptions.goalStatusOptions.length === 0
    ) {
      return new GoalReviewStatusCommentValidationResult(
        false,
        [{ errorType: "REQUIRED" }],
        [],
        0
      );
    }

    const totalGoals = this.goalReviewOptions.goals.length;

    const statusAnswers = answer
      ? (answer as GoalReviewQuestionAnswerValue[])
      : [];
    const statusGoalIds: number[] = statusAnswers
      .filter((x) => x.goalStatusOptionId !== null)
      .map((x) => x.toDoId);

    if (statusGoalIds.length === 0) {
      // No goals with a status assigned, so 0 goals valid
      return new GoalReviewStatusCommentValidationResult(
        false,
        [{ errorType: "REQUIRED" }],
        [],
        totalGoals
      );
    } else {
      // Loop through the goals with a status and find those which
      // also have a comment against them
      const goalComments = enforcedComments.filter((x) => x.goalId !== null);
      const goalsWithStatusAndComment: number[] = [];
      statusGoalIds.forEach((sg) => {
        const commentMatch = goalComments.find(
          (gc) => gc.goalId !== null && gc.goalId === sg
        );
        if (commentMatch) {
          if (this.validateGoalComment(commentMatch)) {
            goalsWithStatusAndComment.push(sg);
          }
        }
      });

      if (goalsWithStatusAndComment.length === totalGoals) {
        // Fully valid
        return new GoalReviewStatusCommentValidationResult(
          true,
          [],
          goalsWithStatusAndComment,
          totalGoals
        );
      } else {
        // Not valid
        return new GoalReviewStatusCommentValidationResult(
          false,
          [{ errorType: "REQUIRED" }],
          goalsWithStatusAndComment,
          totalGoals
        );
      }
    }
  };

  /** Ensures a comment is a valid behaviour (enforced) comment */
  private validateBehaviourComment = (
    comment: BaseCommentDto | null
  ): boolean => {
    if (
      !comment ||
      !comment.comment ||
      !comment.behaviourId ||
      comment.commentType !== "ENFORCED"
    )
      return false;
    return comment.comment.trim().length > 0;
  };

  /** Ensures a comment is a valid behaviour (enforced) comment */
  private validateGoalComment = (comment: BaseCommentDto | null): boolean => {
    if (
      !comment ||
      !comment.comment ||
      !comment.goalId ||
      comment.commentType !== "ENFORCED"
    )
      return false;
    return comment.comment.trim().length > 0;
  };

  private validateAdvancedTaskManagementQuestion = (
    config: TaskManagementConfigDto | null,
    formType: FormType
  ): ValidationResult => {
    if (config) {
      // Before checking various mode/task type restrictions we can start by checking if enforce comments
      // is enabled and if so, check the tasks satisfy that need.
      if (
        config.enforceComments &&
        config.liveAndNewTasks.some((x) => x.enforcedComments)
      ) {
        // Use the 'Tasks' property as this has filtered out any 'New' tasks
        var nonNewTasks = config.tasks;

        const areAllTasksOwnerConditionSatisfied = nonNewTasks.every((task) => {
          return task.enforcedComments.areOwnerCountConditionsSatisfied;
        });

        const areAllTasksManagerRoleConditionSatisfied = nonNewTasks.every(
          (task) => {
            return task.enforcedComments.areManagerRoleCountConditionsSatisfied;
          }
        );

        // In the Journey we only need to make sure the task owner conditions are satisfied
        if (formType === "JOURNEY" && !areAllTasksOwnerConditionSatisfied) {
          return new ValidationResult(false, [
            { errorType: "FAILED-ENFORCED-TASK-COMMENT" },
          ]);
        }

        // In the Collab Doc we need to make sure both the task owner and manager role conditions are satisfied
        if (
          formType === "COLLAB-DOC" &&
          (!areAllTasksOwnerConditionSatisfied ||
            !areAllTasksManagerRoleConditionSatisfied)
        ) {
          return new ValidationResult(false, [
            { errorType: "FAILED-ENFORCED-TASK-COMMENT" },
          ]);
        }
      }

      // Before checking validation counts, we need to check if there are any active overdue tasks.
      // If there is then we need to fail validation as the user needs to update those first.
      if (
        config.liveAndNewTasks.some((x) => x.status === "ACTIVE" && x.isOverdue === true)
      ) {
        return new ValidationResult(false, [{ errorType: "OVERDUE-TASKS" }]);
      }

      // Get the validation counts for the task type from either the TaskType, or the question validation settings
      // as these can override it, e.g. to enforce a user must add at least one new task
      const validationCounts = advancedTaskHelper.getValidationCounts(
        config.minTaskCount,
        config.maxTaskCount,
        this.validation
      );

      const doesTaskTypeHaveRestrictions =
        validationCounts.restrictionType !== "NONE";
      const doesModeRequireTasksBeClosed =
        config.questionMode === "TASK-REVIEW-ONLY" ||
        config.questionMode === "TASK-REVIEW-AND-CREATION";

      // Need to check this as in some scenarios (e.g. TaskCheckInOnly/TaskReviewOnly) we don't allow adding
      // any new tasks so we aren't able to re-enforce any restrictions
      const doesModeEnforceRestrictions =
        config.questionMode === "TASK-CHECK-IN-AND-CREATION" ||
        config.questionMode === "TASK-REVIEW-AND-CREATION" ||
        config.questionMode === "TASK-CREATION-ONLY";

      // Within the collab doc, if there is a review form against the task type and we have completed tasks
      // then we need to check those have reviewed against that form
      const shouldPerformReviewSimpleFormCheck =
        formType === "COLLAB-DOC" &&
        config.doesTaskTypeHaveReviewForm &&
          // If we are in task creation mode only we need to check only the newTasks
          (config.questionMode === "TASK-CREATION-ONLY" 
          ? config.newTasks.some((x) => x.status === "COMPLETED") 
          : config.liveAndNewTasks.some((x) => x.status === "COMPLETED") );

      // If there are restrictions, and we can enforce them, then we need to check the restrictions are valid
      if (doesTaskTypeHaveRestrictions && doesModeEnforceRestrictions) {
        // Get the tasks for the two different sources
        const activeTasks = config.liveAndNewTasks.filter((x) => x.status === "ACTIVE");

        // Validate the task counts against the restrictions
        const restrictionsAreSatisfied =
          taskRestrictionHelper.doesUserHaveTasksThatSatisfyRestrictions(
            activeTasks,
            validationCounts.min,
            validationCounts.max,
            validationCounts.restrictionType,
            config.categories
          );

        let nonNewTasks: AdvancedTaskDto[] = [];
        if (restrictionsAreSatisfied) {
          if (doesModeRequireTasksBeClosed) {
            // Restrictions are satisfied, but tasks aren't allow to remain open
            // Check if there are any tasks that are not new and are still open
            nonNewTasks = config.tasks;

            // If dual prep journey then we also need to filter out any tasks that were created in other
            // related answer sets
            if (config.isDualPrepJourney) {
              nonNewTasks = nonNewTasks.filter(
                (x) =>
                  x.createdInAnswerSetGuidId == null ||
                  !config.dualPrepAnswerSetGuidIds?.includes(
                    x.createdInAnswerSetGuidId
                  )
              );
            }

            const activeTasks = nonNewTasks.filter(
              (x) => x.status === "ACTIVE"
            );

            if (activeTasks.length === 0) {
              return this.performFinalAdvancedTaskValidationCheckThenReturnSuccess(
                shouldPerformReviewSimpleFormCheck,
                config
              );
            } else {
              return new ValidationResult(false, [
                { errorType: "TASKS-REMAIN-OPEN" },
              ]);
            }
          } else {
            // Restrictions are satisfied, and tasks are allowed to remain open
            return this.performFinalAdvancedTaskValidationCheckThenReturnSuccess(
              shouldPerformReviewSimpleFormCheck,
              config
            );
          }
        } else {
          return new ValidationResult(false, [
            { errorType: "FAILED-TASK-RESTRICTIONS" },
          ]);
        }
      } else {
        if (doesModeRequireTasksBeClosed) {
          // Check if there are any tasks that are not new and are still open
          nonNewTasks = config.tasks;

          // If dual prep journey then we also need to filter out any tasks that were created in other
          // related answer sets
          if (config.isDualPrepJourney) {
            nonNewTasks = nonNewTasks.filter(
              (x) =>
                x.createdInAnswerSetGuidId == null ||
                !config.dualPrepAnswerSetGuidIds?.includes(
                  x.createdInAnswerSetGuidId
                )
            );
          }

          const activeTasks = nonNewTasks.filter((x) => x.status === "ACTIVE");

          if (activeTasks.length === 0) {
            return this.performFinalAdvancedTaskValidationCheckThenReturnSuccess(
              shouldPerformReviewSimpleFormCheck,
              config
            );
          } else {
            return new ValidationResult(false, [
              { errorType: "TASKS-REMAIN-OPEN" },
            ]);
          }
        } else {
          // There are no restrictions to enforce, and tasks are allowed to remain open
          return this.performFinalAdvancedTaskValidationCheckThenReturnSuccess(
            shouldPerformReviewSimpleFormCheck,
            config
          );
        }
      }
    } else {
      // Should never happen as its a task management question so there should be a config.
      // If there isn't then something is wrong.
      return new ValidationResult(false);
    }
  };

  private performFinalAdvancedTaskValidationCheckThenReturnSuccess = (
    shouldPerformReviewSimpleFormCheck: boolean,
    config: TaskManagementConfigDto
  ): ValidationResult => {
    // Final check before returning a successfull result is...
    // Check whether we need to check the completed tasks have a review form response
    if (shouldPerformReviewSimpleFormCheck) {
      if (!this.checkCompletedTasksForReviewFormResponses(config)) {
        return new ValidationResult(false, [
          { errorType: "TASK-REQUIRES-REVIEW-FORM-RESPONSE" },
        ]);
      }
    }

    return new ValidationResult(true);
  };

  private checkCompletedTasksForReviewFormResponses = (
    config: TaskManagementConfigDto
  ): boolean => {
    // Retrieve all completed tasks that are not new in this journey
    const tasks = config.questionMode === "TASK-CREATION-ONLY" ? config.newTasks : config.liveAndNewTasks;
    const completedTasks = tasks.filter(
      (x) => x.status === "COMPLETED"
    );

    return completedTasks.every((x) => x.hasReviewFormResponse);
  };
}

export default FormQuestion;
