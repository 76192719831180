import produce from "immer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { collabDocQuestionSequenceHelper } from "../../helpers/questionSequenceHelpers";
import { AnswerSetApprovalStatus } from "../../types/collab-docs";
import { CollabDocFormDto } from "../../types/dtos/collab-docs";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import {
  EnforcedCommentType,
  FormComplexities,
  FormQuestionDto,
  NewCommentDto,
  SavedCommentDto,
} from "../../types/dtos/forms";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import {
  QuestionAnswer,
  QuestionAnswerType,
  QuestionAnswerValue,
  QuestionTasks,
} from "../../types/forms";
import FormQuestion from "../../types/forms/FormQuestion";
import CollabDocQuestion from "./CollabDocQuestion";
import AdvancedTaskDto from "../../types/dtos/forms/AdvancedTaskDto";
import { CollabDocState } from "../../state/CollabDoc/CollabDocState";

interface CollabDocFormProps {
  answerSetUniqueId: string;
  answerSetDateCreated: Date;
  form: CollabDocFormDto;
  /** A hex background colour, without the hash */
  backgroundColour: string;
  approvalStatus: AnswerSetApprovalStatus;
  subjectUser: BaseUserDetailsDto;
  isReadOnly: boolean;
  isLocked: boolean;
  comments: Array<SavedCommentDto>;
  participants: Array<UserBasicDetailsDto>;
  answers: Array<QuestionAnswer>;
  tasks: Array<QuestionTasks>;
  activeQuestionId: string | null;
  showValidationErrors: boolean;
  showChangeDetails: boolean;
  flaggedChanges: CollabDocFlaggedChangeDto[];
  formComplexities: FormComplexities;
  /** Whether or not we're in Dual Prep prep mode */
  isInPrepMode: boolean;
  isPrinting: boolean;
  /** The date/time the form data was loaded (must be UTC) */
  dateLoaded: Date;
  docState: CollabDocState;
  setDocState(value: Partial<CollabDocState>): void;
  /** A method to call to update the answer state */
  onValueChange(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerType: QuestionAnswerType
  ): QuestionAnswer | null;
  /** When wanting to save a question's answer via the API */
  onValueSave(
    answer: QuestionAnswer,
    nonStateValue: QuestionAnswer | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the answer state (e.g. on save failure) */
  onRetryValueSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for tasks (e.g. on save failure) */
  onRetryTasksSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for enforced comments (e.g. on save failure) */
  onRetryEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** The active question determines the state of the comments sidebar */
  onChangeActiveQuestion(activeQuestionId: string): void;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(
    newComment: NewCommentDto,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to delete a comment */
  onCommentDelete(
    commentId: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to update the state value (single) enforced comment for this question (if one exists, otherwise creates a new comment) */
  onEnforcedCommentEdit(
    questionId: string,
    commentText: string,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ): SavedCommentDto;
  /** A method to call to update the enforced comment in the database */
  onEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(
    questionTasks: QuestionTasks,
    onSuccess: () => void,
    onError: () => void
  ): void;
}

const CollabDocForm = ({
  answerSetUniqueId,
  answerSetDateCreated,
  form,
  backgroundColour,
  approvalStatus,
  isReadOnly,
  isLocked,
  comments,
  tasks,
  participants,
  answers,
  activeQuestionId,
  showValidationErrors,
  dateLoaded,
  subjectUser,
  showChangeDetails,
  flaggedChanges,
  formComplexities,
  isInPrepMode,
  isPrinting,
  docState,
  setDocState,
  onValueChange,
  onValueSave,
  onChangeActiveQuestion,
  onCommentsSeen,
  onCommentAdd,
  onCommentDelete,
  onChangeQuestionTasks,
  onEnforcedCommentEdit,
  onEnforcedCommentSave,
  onRetryValueSave,
  onRetryTasksSave,
  onRetryEnforcedCommentSave,
}: CollabDocFormProps) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<FormQuestionDto[]>([]);

  /** Get the questions to display, in the correct sequence,
   * including taking into account conditional questions and whether
   * their parents have answers already or not
   */
  const getQuestionsToDisplayForForm = (): Array<FormQuestionDto> => {
    // Check there are questions to render
    if (
      !form ||
      !form.questions ||
      form.questions.length === 0 ||
      !form.questions[0]
    ) {
      return [];
    }

    let output: Array<FormQuestionDto> = [];

    const firstQuestion = new FormQuestion(form.questions[0]);
    output.push(firstQuestion);

    const firstQuestionAnswer = getAnswerForQuestion(firstQuestion.questionId);
    let nextQuestionId =
      collabDocQuestionSequenceHelper.getNextQuestionToDisplay(
        firstQuestion,
        firstQuestionAnswer,
        form.questions
      );

    let loopCounter = 0;
    const loopCounterSafeLimit = 1000;
    while (nextQuestionId != null && loopCounter < loopCounterSafeLimit) {
      const dtoMatch = form.questions.find(
        (x) => x.questionId === nextQuestionId // eslint-disable-line no-loop-func
      );
      if (!dtoMatch) {
        // Unable to match question. This shouldn't happen. Break the loop.
        nextQuestionId = null;
      } else {
        // Add the matching question to the output, and find the next question after that
        const nextQuestion = new FormQuestion(dtoMatch);
        output.push(nextQuestion);

        // Now attempt to calculate the next question
        const loopQuestionAnswer = getAnswerForQuestion(
          nextQuestion.questionId
        );
        nextQuestionId =
          collabDocQuestionSequenceHelper.getNextQuestionToDisplay(
            nextQuestion,
            loopQuestionAnswer,
            form.questions
          );
      }
    }

    return output;
  };

  const getAnswerForQuestion = (questionId: string): QuestionAnswerValue => {
    const match = answers.find((x) => x.questionId === questionId);
    return match ? match.answer : null;
  };

  useEffect(() => {
    const nextQuestionState = getQuestionsToDisplayForForm();
    setQuestions(nextQuestionState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const nextQuestionState = getQuestionsToDisplayForForm();
    setQuestions(nextQuestionState);
  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeAdvancedTasks = (questionId: string, tasks?: AdvancedTaskDto[], cancelledTasks?: AdvancedTaskDto[], newTasks?: AdvancedTaskDto[]) => {
    // Create new form state and update the relevant form/question with new tasks/cancelledTasks
    const nextFormState = docState.forms.map((f) => {
      if (f.formId === form.formId) {
        const newFormState = {
          ...f,
        };
        const questionIndex = newFormState.questions.findIndex(
          (x) => x.questionId === questionId
        );
        if (tasks) {
          newFormState.questions[questionIndex].taskManagementConfig!.tasks = tasks;
        }
        if (cancelledTasks) {
          newFormState.questions[questionIndex].taskManagementConfig!.cancelledTasks = cancelledTasks;
        }
        if (newTasks) {
          newFormState.questions[questionIndex].taskManagementConfig!.newTasks = newTasks;
        }

        // Combine the task and newTasks and update the joint list
        const liveAndNewTasks = [...newFormState.questions[questionIndex].taskManagementConfig!.tasks, ...newFormState.questions[questionIndex].taskManagementConfig!.newTasks];
        newFormState.questions[questionIndex].taskManagementConfig!.liveAndNewTasks = liveAndNewTasks;

        return newFormState;
      }
      return f;
    });

    setDocState({ forms: nextFormState });
  };

  return (
    <div className="px-2 lg:px-4">
      <div className="shadow-xl rounded-lg mb-6 bg-white">
        <h3 className=" px-4 py-3 text-left text-gray-600 bg-gray-50 border-b border-gray-200 rounded-t-lg font-semibold">
          {t(form.title)}
        </h3>
        <div className="pb-2">
          {questions.map((q) => {
            // Get the comments for this question
            const questionComments = comments.filter(
              (x) => x.questionId === q.questionId
            );
            // Get the flagged changes for this question
            const questionFlaggedChanges = flaggedChanges.filter(
              (x) => x.questionId === q.questionId
            );

            const formQuestion = new FormQuestion(q);

            const currentAnswer =
              answers.find((x) => x.questionId === formQuestion.questionId) ||
              null;

            const questionTasks = tasks.find(
              (x) => x.questionId === formQuestion.questionId
            );
            return (
              <CollabDocQuestion
                answerSetUniqueId={answerSetUniqueId}
                answerSetDateCreated={answerSetDateCreated}
                key={`question_${q.questionId}`}
                question={formQuestion}
                currentAnswer={currentAnswer}
                onValueChange={onValueChange}
                onValueSave={onValueSave}
                isReadOnly={isReadOnly}
                isDocLocked={isLocked}
                formActiveQuestionId={activeQuestionId}
                comments={questionComments}
                flaggedChanges={questionFlaggedChanges}
                showPlanningResponses={form.showPlanningResponses}
                participants={participants}
                formApprovalStatus={approvalStatus}
                showValidationErrors={showValidationErrors}
                dateFormLoaded={dateLoaded}
                subjectUser={subjectUser}
                tasks={questionTasks ? questionTasks : null}
                formColor={backgroundColour}
                showChangeDetails={showChangeDetails}
                formComplexities={formComplexities}
                isInPrepMode={isInPrepMode}
                isPrinting={isPrinting}
                onQuestionFocus={onChangeActiveQuestion}
                onCommentAdd={onCommentAdd}
                onCommentDelete={onCommentDelete}
                onCommentsSeen={onCommentsSeen}
                onChangeQuestionTasks={onChangeQuestionTasks}
                onEnforcedCommentEdit={onEnforcedCommentEdit}
                onEnforcedCommentSave={onEnforcedCommentSave}
                onRetryValueSave={onRetryValueSave}
                onRetryTasksSave={onRetryTasksSave}
                onRetryEnforcedCommentSave={onRetryEnforcedCommentSave}
                onChangeAdvancedTasks={onChangeAdvancedTasks}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollabDocForm;
