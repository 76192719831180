import ResultStatus from "../../types/dtos/generic/ResultStatus";
import { SimpleFormContent } from "../../types/dtos/simple-forms";
import { AdvancedTaskStatus } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { AdvancedTaskStatusChangeDto } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatusChangeDto";
import { UserTaskDetail } from "../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import UserTaskListingForJourney from "../../types/dtos/tasks/advanced-tasks/UserTaskListingForJourney";
import UserTaskListingForManagerResponse from "../../types/dtos/tasks/advanced-tasks/UserTaskListingForManagerResponse";
import UserTaskListingForOwnerResponse from "../../types/dtos/tasks/advanced-tasks/UserTaskListingForOwnerResponse";
import { AnswerSetUserTaskDetails } from "../../types/dtos/tasks/advanced-tasks/add-popup/AnswerSetUserTaskDetails";
import { EditUserTaskDto } from "../../types/dtos/tasks/advanced-tasks/add-popup/EditUserTaskDto";
import { NewUserTaskDto } from "../../types/dtos/tasks/advanced-tasks/add-popup/NewUserTaskDto";
import { AdvancedTaskListingResponse } from "../../types/dtos/tasks/advanced-tasks/api-response/AdvancedTaskListingResponse";
import AdvancedTaskReOpenResult from "../../types/dtos/tasks/advanced-tasks/api-response/AdvancedTaskReOpenResult";
import { UserTaskChangeLogResponse } from "../../types/dtos/tasks/advanced-tasks/api-response/UserTaskChangeLogResponse";
import { UserTaskSaveResponse } from "../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import UserTaskCommentDto from "../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import { TaskEditPopupUsageScenario } from "../../types/tasks/TaskEditPopupUsageScenario";
import { apiClient } from "../apiClient";

class advancedTasksApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Gets the page details for the individual task management page as well as the active task list
   * for the loggedInUser */
  listTasksForOwner(
    taskTypeId: string,
    onSuccess: (data: UserTaskListingForOwnerResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/list-tasks-for-owner?taskTypeId=" + taskTypeId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the task list for the loggedInUser by tab/taskStatusFilter for the individual task management page */
  listTasksForOwnerByTab(
    taskTypeId: string,
    taskStatusFilter: AdvancedTaskStatus,
    onSuccess: (data: AdvancedTaskListingResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/list-tasks-for-owner-by-tab?taskTypeId=" +
      taskTypeId +
      "&taskStatusFilter=" +
      taskStatusFilter
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the page details for the team task management page as well as the loggedInUsers employees
   * and their active tasks
   */
  listTasksForManager(
    taskTypeId: string,
    onSuccess: (data: UserTaskListingForManagerResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/list-tasks-for-manager?taskTypeId=" +
      taskTypeId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the task list for the given userId and the taskStatusFilters for the team management page */
  listTasksForManagerByTabAndUser(
    taskTypeId: string,
    taskStatusFilter: string,
    userId: number,
    onSuccess: (data: AdvancedTaskListingResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/list-tasks-for-manager-by-tab-and-user?taskTypeId=" +
      taskTypeId +
      "&taskStatusFilter=" +
      taskStatusFilter +
      "&userId=" +
      userId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the body form to display when creating a new user task */
  getBodyFormForTaskType(
    taskTypeId: string,
    onSuccess: (data: SimpleFormContent) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/get-body-form-for-task-type?taskTypeId=" +
      taskTypeId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the task details for a given userTaskId */
  getTaskDetails(
    userTaskId: string,
    onSuccess: (data: UserTaskDetail) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/get-task-details?userTaskId=" + userTaskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Creates a new task with the given details */
  createTask(
    newTask: NewUserTaskDto,
    onSuccess: (data: UserTaskSaveResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/create-task",
      {
        body: JSON.stringify(newTask),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Updates an existing task with the given details */
  editTask(
    existingTask: EditUserTaskDto,
    onSuccess: (data: UserTaskSaveResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/advanced-tasks/edit-task", {
      body: JSON.stringify(existingTask),
    }).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the change log for the given userTaskID */
  changeLogs(
    userTaskId: string,
    onSuccess: (data: UserTaskChangeLogResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/change-logs?userTaskId=" + userTaskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the comments for the given userTaskId */
  listComments(
    userTaskId: string,
    onSuccess: (data: UserTaskCommentDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/advanced-tasks/list-comments?userTaskId=${userTaskId}`
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the cancel form for a given userTaskId*/
  getCancelForm(
    userTaskId: string,
    onSuccess: (data: SimpleFormContent) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/get-cancel-form?userTaskId=" + userTaskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Cancels the given userTaskId when there's no form to post */
  cancelTaskWithoutForm(
    userTaskId: string,
    answerSetDetails: AnswerSetUserTaskDetails | null,
    onSuccess: (data: ResultStatus) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/cancel-task-without-form?userTaskId=" +
      userTaskId,
      {
        body: JSON.stringify(answerSetDetails),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Cancels the given userTaskId and saves the answers in the displayed form */
  cancelTaskWithForm(
    details: AdvancedTaskStatusChangeDto,
    onSuccess: (data: UserTaskSaveResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/cancel-task-with-form",
      {
        body: JSON.stringify(details),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the cancel form for a given userTaskId*/
  getCloseForm(
    userTaskId: string,
    onSuccess: (data: SimpleFormContent) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/get-close-form?userTaskId=" + userTaskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the review form for a given userTaskId*/
  getReviewForm(
    userTaskId: string,
    onSuccess: (data: SimpleFormContent) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/get-review-form?userTaskId=" + userTaskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Closes/completes the given userTaskId when there's no form to post */
  closeTaskWithoutForm(
    userTaskId: string,
    origin: TaskEditPopupUsageScenario,
    answerSetDetails: AnswerSetUserTaskDetails | null,
    onSuccess: (data: ResultStatus) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/close-task-without-form?userTaskId="
      + userTaskId
      + "&origin=" + origin,
      {
        body: JSON.stringify(answerSetDetails),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Closes/completes the given userTaskId and saves the answers in the displayed form */
  closeTaskWithForm(
    details: AdvancedTaskStatusChangeDto,
    origin: TaskEditPopupUsageScenario,
    onSuccess: (data: UserTaskSaveResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/close-task-with-form?origin=" + origin, 
      {
        body: JSON.stringify(details),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Review the given userTaskId and saves the answers in the displayed form */
  reviewTaskWithForm(
    details: AdvancedTaskStatusChangeDto,
    onSuccess: (data: UserTaskSaveResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/review-task-with-form",
      {
        body: JSON.stringify(details),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Re-opens a task and makes it active again */
  reOpenTask(
    userTaskId: string,
    comment: string,
    answerSetDetails: AnswerSetUserTaskDetails | null,
    onSuccess: (data: AdvancedTaskReOpenResult) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/re-open-task",
      {
        body: JSON.stringify({ userTaskId, comment, answerSetDetails }),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Adds a comment to the given userTaskId */
  addComment(
    userTaskId: string,
    comment: string,
    onSuccess: (data: ResultStatus) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/add-comment",
      {
        body: JSON.stringify({ userTaskId, comment }),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Edit the comment for a given commentId */
  editComment(
    userTaskId: string,
    commentId: string,
    comment: string,
    onSuccess: (data: ResultStatus) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/edit-comment",
      {
        body: JSON.stringify({ userTaskId, commentId, comment }),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Deletes the comment against the given userTaskId */
  deleteComment(
    userTaskId: string,
    commentId: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/delete-comment?userTaskId=" +
      userTaskId +
      "&commentId=" +
      commentId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Clears all of the highlightToEmployee or highlightToManager for a given taskType and user */
  clearAllTaskHighlightsForUser(
    taskTypeId: string,
    userId: number,
    onSuccess: (data: ResultStatus) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/clear-all-task-highlights-for-user?taskTypeId=" +
      taskTypeId +
      "&userId=" +
      userId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Deletes the task by the given id */
  deleteTask(
    taskId: string,
    onSuccess: (data: UserTaskSaveResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/advanced-tasks/delete-task?taskId=" + taskId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the tasks listing for use in the journey/collab doc, main function is to act as a refresh
   * tasks function */
  getTasksForJourney(
    employeeId: number,
    advancedTaskTypeId: string | null,
    minAdvancedTaskReviewDate: Date | null | undefined,
    advancedTaskReviewDateType: number | null | undefined,
    enforceComments: boolean | null | undefined,
    answerSetId: number | null | undefined,
    answerSetGuidId: string | null | undefined,
    answerSetDateCreated: Date | null | undefined,
    isCompleted: boolean,
    isDualPrepJourney: boolean,
    onSuccess: (data: UserTaskListingForJourney) => void,
    onError: (error: any) => void
  ) {
    let url =
      "dashboards/advanced-tasks/get-tasks-for-journey?employeeId=" +
      employeeId +
      "&isCompleted=" +
      isCompleted +
      "&isDualPrepJourney=" +
      isDualPrepJourney;
    if (advancedTaskTypeId) {
      url += "&advancedTaskTypeId=" + advancedTaskTypeId;
    }
    if (minAdvancedTaskReviewDate) {
      url +=
        "&minAdvancedTaskReviewDate=" + minAdvancedTaskReviewDate.toString();
    }
    if (
      advancedTaskReviewDateType != null &&
      advancedTaskReviewDateType != undefined
    ) {
      url +=
        "&advancedTaskReviewDateType=" + advancedTaskReviewDateType.toString();
    }
    if (enforceComments != null && enforceComments != undefined) {
      url += "&enforceComments=" + enforceComments.toString();
    }
    if (answerSetId != null && answerSetId != undefined) {
      url += "&answerSetId=" + answerSetId.toString();
    }
    if (answerSetGuidId) {
      url += "&answerSetGuidId=" + answerSetGuidId;
    }
    if (answerSetDateCreated) {
      url += "&answerSetDateCreated=" + answerSetDateCreated.toString();
    }
    return apiClient(this.accessToken, url).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default advancedTasksApi;
