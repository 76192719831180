import { useEffect, useState, lazy } from "react";
import { useAuth } from "react-oidc-context";
import { Routes, Route, useMatch, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import "./config/content/i18n";
import { StandardLayout, ScrollTopOnPageChange } from "./components/layout";
import userApi from "./api/user/userApi";
import {
  AuthCallback,
  SsoAuthCallback,
  AuthErrorPage,
  AuthLogout,
} from "./routes/auth";
import FullScreenLoaderWithMessage from "./components/loaders/FullScreenLoaderWithMessage";
import {
  MyDashboard,
  ManagerDashboard,
  OgAppRedirect,
  ErrorPage,
} from "./routes";
import AppRoutes from "./routes/AppRoutes";
import UserContext, {
  UserContextInterface,
  userContextDefaultValue,
} from "./state/UserContext";
import AppContext from "./state/AppContext";
import OverrideNavItem from "./types/nav/OverrideNavItem";
import SignedInUserDto, {
  SignedInUserUpdatedDetailsDto,
} from "./types/dtos/users/SignedInUserDto";
import {
  advancedTaskHelper,
  dateHelper,
  i18nHelper,
  localStorageHelper,
} from "./helpers";
import { OfflineWarning } from "./components/common";
import TestErrorLogger from "./logging/TestErrorLogger";
// Instantiate the singleton logger
import ErrorLogger from "./logging/ErrorLogger";
// Enable behaviour logging to Application Insights
import UserBehaviourLogger from "./logging/UserBehaviourLogger";
import UserwalkthroughType from "./types/generic/UserWalkthroughType";
import { AnalyticsRoot } from "./routes/analytics";
import CollabDocEditor from "./routes/CollabDocEditor";
import CollabDocViewer from "./routes/CollabDocViewer";
import YourJourneyRoot from "./routes/your-journey/YourJourneyRoot";
import YourPeopleRoot from "./routes/your-people/YourPeopleRoot";
import AccessibePlugin from "./components/third-party/AccessibePlugin";
import DemoManagerReset from "./routes/demo/DemoManagerReset";

/** Code splitting pages not used by every user */
const ComponentsDemo = lazy(() => import("./routes/ComponentsDemo"));
const AdminRoot = lazy(() => import("./routes/admin/AdminRoot"));

function App() {
  ErrorLogger.init();
  UserBehaviourLogger.init();

  // Constants
  const auth = useAuth();
  const { t } = useTranslation();
  const apiUsers = new userApi(auth.user?.access_token);

  // Authentication
  const isSsoCallback = useMatch({
    path: AppRoutes.auth.ssoCallback,
    end: false,
  });

  const isLogoutRequest = useMatch({
    path: AppRoutes.auth.logout,
    end: false,
  });

  const isRemoteLogoutRequest = useMatch({
    path: AppRoutes.auth.remoteLogoutCallback,
    end: false,
  });

  const [user, setUser] = useState<SignedInUserDto>(
    userContextDefaultValue.user
  );
  const [userHasLoaded, setUserHasLoaded] = useState<boolean>(false);

  const setUserContext = (newUserDetails: SignedInUserDto) => {
    // Convert the dateLoaded string to a Date object
    newUserDetails.dateLoaded = dateHelper.convertDateFromString(
      newUserDetails.dateLoaded
    )!;
    setUser(newUserDetails);

    // Set the authenticated user context for Application Insights for tracking
    UserBehaviourLogger.setActiveUser({
      id: newUserDetails.id,
      clientName: newUserDetails.client.name,
    });
  };

  /** Update the properties on a user which are allowed to be refreshed */
  const setUpdatedUserDetails = (updates: SignedInUserUpdatedDetailsDto) => {
    const updatedUser: SignedInUserDto = {
      ...user,
      notifications: updates.notifications,
      highlightedTasks: updates.highlightedTasks,
      managerUpdateCount: updates.managerUpdateCount,
      myDashboardMode: updates.myDashboardMode,
      dateLoaded: updates.dateLoaded,
      walkthroughSeen: updates.walkthroughSeen,
    };
    setUserContext(updatedUser);
  };

  const onAdvancedTaskViewed = (
    ownerUserId: number,
    taskTypeId: string,
    userTaskId: string
  ) => {
    const updatedUser: SignedInUserDto = {
      ...user,
      highlightedTasks: advancedTaskHelper.removeTaskHighlightForUserContext(
        user.id,
        ownerUserId,
        taskTypeId,
        userTaskId,
        user.highlightedTasks
      ),
    };
    setUserContext(updatedUser);
  };

  useEffect(() => {
    // When the user details change, update the user deails for error logging
    const logUser =
      user?.id > 0
        ? {
            clientId: user.client.id,
            clientName: user.client.name,
            id: user.id,
            fullName: user.fullName,
          }
        : null;
    ErrorLogger.setActiveUser(logUser);
  }, [user.id]);

  // Set the UserContext
  const userContextProvider: UserContextInterface = {
    user,
    setUser: setUserContext,
    setUpdatedUserDetails,
    removeTaskHighlight: onAdvancedTaskViewed,
  };

  // Page title is set via Context due to the structure of the components
  // and child components needing to update the title in TopBar.tsx
  const [pageTitle, setPageTitle] = useState<string>("");
  const [showPageTitleAccent, setShowPageTitleAccent] = useState<boolean>(true);
  const [overrideNavItem, setOverrideNavItem] =
    useState<OverrideNavItem | null>(null);
  const [
    activeDashboardTypeForWalkthrough,
    setActiveDashboardTypeForWalkthrough,
  ] = useState<UserwalkthroughType | null>(null);

  // Loaders
  const loadLoggedInUserDetails = () => {
    if (!auth.isAuthenticated) return;

    // On successful login, call the API to retrieve the logged in user's details
    auth.clearStaleState();

    apiUsers.getLoggedInUserDetails(
      (apiUserDto: SignedInUserDto) => {
        setUserHasLoaded(true);
        userContextProvider.setUser(apiUserDto);

        // Set the correct file to load translations from
        i18nHelper.setCurrentLanguage(
          apiUserDto.client.id,
          apiUserDto.i18n.cultureCode
        );

        // Set the local storage value for the client MVC route,
        // so we can redirect there if the user gets logged out
        localStorageHelper.setItem(
          "USER_MVC_APP_URL",
          apiUserDto.client.mvcAppUrl
        );
      },
      (error) => {
        console.error("User api error", error);
        ErrorLogger.log("API error loading user details: " + error);
      }
    );
  };

  // Side effects
  useEffect(() => {
    loadLoggedInUserDetails();
  }, [auth.isAuthenticated]);

  // Auth events
  switch (auth.activeNavigator) {
    case "signinSilent":
      return (
        <FullScreenLoaderWithMessage
          message={t("Common.Auth.SignInSilentMessage")}
        />
      );
    case "signoutRedirect":
      return (
        <FullScreenLoaderWithMessage
          message={t("Common.Auth.SignOutRedirectMessage")}
        />
      );
  }

  if (auth.isLoading) {
    return (
      <FullScreenLoaderWithMessage
        message={t("Common.Auth.SignInSilentMessage")}
      />
    );
  }

  if (auth.error) {
    return <AuthErrorPage errorMessage={auth.error.message} />;
  }

  const hasUrlHashParam =
    window.location.hash && window.location.hash.indexOf("#code=") === 0;
  if (
    (!auth.isAuthenticated || (auth.isAuthenticated && hasUrlHashParam)) &&
    !isSsoCallback &&
    !isLogoutRequest &&
    !isRemoteLogoutRequest
  ) {
    // If redirected with a code hash, as part of the `returnUrl` redirect process,
    // redirect to the ssoCallbackUrl, but pass the `returnUrl` as a querystring param
    // so we can redirect there when the sign-in process has completed
    let returnUrl = window.location.pathname + window.location.search;
    returnUrl = encodeURIComponent(returnUrl);
    let ssoCallbackUrl = `${AppRoutes.auth.ssoCallback}?returnUrl=${returnUrl}`;

    if (hasUrlHashParam) {
      ssoCallbackUrl = ssoCallbackUrl + window.location.hash;
    }

    window.location.href = ssoCallbackUrl;
    return null;
  }

  // Show the loader until the user details have been loaded,
  // to prevent colour theme flicker
  const isMissingUserDetails = userHasLoaded && user.id === 0;
  if (
    (!userHasLoaded || isMissingUserDetails) &&
    !window.location.pathname.toLowerCase().startsWith("/auth")
  ) {
    if (isMissingUserDetails) {
      loadLoggedInUserDetails();
    }

    return (
      <FullScreenLoaderWithMessage
        message={t("Common.Auth.SignInSilentMessage")}
      />
    );
  }

  return (
    <AppInsightsContext.Provider value={UserBehaviourLogger.reactPlugin}>
      <HelmetProvider>
        <UserContext.Provider value={userContextProvider}>
          <OfflineWarning user={userContextProvider.user} />
          <AppContext.Provider
            value={{
              pageTitle,
              setPageTitle,
              showPageTitleAccent,
              setShowPageTitleAccent,
              overrideNavItem,
              setOverrideNavItem,
              activeDashboardTypeForWalkthrough,
              setActiveDashboardTypeForWalkthrough,
            }}
          >
            <>
              {/* Set the client accent colour via a CSS variable in the head tag */}
              <Helmet>
                <title>
                  {userContextProvider.user.client.productName}
                  {pageTitle ? " - " + pageTitle : ""}
                </title>
                <style type="text/css">
                  {`:root {
                  --color-accent: #${userContextProvider.user.client.accentHexColour};
                  --color-primary: #${userContextProvider.user.client.primaryHexColour};
                  --color-primary-with-opacity: #${userContextProvider.user.client.primaryHexColour}10;
                }`}
                </style>
              </Helmet>
              <ScrollTopOnPageChange>
                <Routes>
                  <Route path="/" element={<StandardLayout />}>
                    <Route
                      index
                      element={
                        <Navigate to={AppRoutes.yourJourney.root} replace />
                      }
                    />
                    <Route
                      path={AppRoutes.yourJourney.root}
                      element={<MyDashboard />}
                    />
                    <Route
                      path={AppRoutes.yourJourney.root + "/*"}
                      element={<YourJourneyRoot />}
                    />
                    <Route
                      path={AppRoutes.yourPeople.root}
                      element={<ManagerDashboard />}
                    />
                    <Route
                      path={AppRoutes.yourPeople.root + "/*"}
                      element={<YourPeopleRoot />}
                    />
                    <Route
                      path={AppRoutes.companyDashboard}
                      element={<OgAppRedirect redirectTo="COMPANY_DASHBOARD" />}
                    />
                    <Route path={AppRoutes.collaborativeDocument.standardPath}>
                      {/** These routes have different `key` attrs as that enables a proper reload
                       * when navigating from a form-specific view of a collab doc, to a brand
                       * new collab doc. Without the keys, the page doesn't refresh
                       */}
                      <Route
                        path=":singleFormId"
                        element={<CollabDocEditor key="form-specific-view" />}
                      />
                      <Route
                        path=""
                        element={<CollabDocEditor key="general-doc" />}
                      />
                    </Route>
                    <Route
                      path={AppRoutes.collaborativeDocument.viewCollabDoc}
                      element={<CollabDocViewer />}
                    />
                    <Route
                      path={AppRoutes.companyDashboard}
                      element={<OgAppRedirect redirectTo="COMPANY_DASHBOARD" />}
                    />
                    <Route
                      path={AppRoutes.help}
                      element={<OgAppRedirect redirectTo="HELP" />}
                    />
                    <Route
                      path={AppRoutes.profile}
                      element={<OgAppRedirect redirectTo="PROFILE" />}
                    />
                    <Route
                      path={AppRoutes.changePassword}
                      element={<OgAppRedirect redirectTo="CHANGE_PASSWORD" />}
                    />
                    <Route
                      path={AppRoutes.yourGoals}
                      element={<OgAppRedirect redirectTo="YOUR_GOALS" />}
                    />
                    <Route
                      path={AppRoutes.notificationPreferences}
                      element={
                        <OgAppRedirect redirectTo="NOTIFICATION_PREFERENCES" />
                      }
                    />
                    <Route
                      path={AppRoutes.reviewHistory.path}
                      element={<OgAppRedirect redirectTo="REVIEW_HISTORY" />}
                    />
                    <Route
                      path={AppRoutes.legacyTaskList.path}
                      element={<OgAppRedirect redirectTo="TASK_LIST" />}
                    />
                    <Route
                      path={AppRoutes.analytics.classic}
                      element={<OgAppRedirect redirectTo="ANALYTICS" />}
                    />
                    <Route
                      path={AppRoutes.logout}
                      element={<OgAppRedirect redirectTo="LOGOUT" />}
                    />
                    <Route
                      path={AppRoutes.componentsDemo}
                      element={<ComponentsDemo />}
                    />
                    <Route
                      path={AppRoutes.demo.managerReset}
                      element={<DemoManagerReset />}
                    />
                    <Route
                      path={AppRoutes.admin.root + "/*"}
                      element={<AdminRoot />}
                    />
                    <Route
                      path={AppRoutes.admin.userManagement}
                      element={<OgAppRedirect redirectTo="ADMIN" />}
                    />
                    <Route
                      path={AppRoutes.admin.classicUserDetailAdmin.path}
                      element={
                        <OgAppRedirect redirectTo="ADMIN-EMPLOYEE-DETAIL" />
                      }
                    />
                    <Route
                      path={AppRoutes.analytics.root + "/*"}
                      element={<AnalyticsRoot />}
                    />
                    <Route
                      path="error-log-test"
                      element={<TestErrorLogger />}
                    />
                    <Route path="auth">
                      <Route path="callback" element={<AuthCallback />} />
                      <Route
                        path="sso-callback"
                        element={<SsoAuthCallback />}
                      />
                      <Route path="logout" element={<AuthLogout />} />
                    </Route>

                    <Route
                      path="*"
                      element={
                        <ErrorPage
                          errorTitle={t("Errors.404.Title")}
                          shortDescription={t("Errors.404.Body")}
                        />
                      }
                    />
                  </Route>
                </Routes>
              </ScrollTopOnPageChange>
            </>
          </AppContext.Provider>
          <AccessibePlugin />
        </UserContext.Provider>
      </HelmetProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
