import { useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { debounce } from "lodash";
import { t } from "i18next";
import parse from "html-react-parser";
import cx from "classnames";
import {
  SimpleFormAnswer,
  SimpleFormMultipleChoiceAnswer,
  SimpleFormQuestion,
  SimpleFormValidationError,
} from "../../../types/dtos/simple-forms";
import {
  Badge,
  CheckBoxList,
  FormDropDownList,
  Label,
  RadioButtonGroup,
  Slider,
  TextArea,
  TextInput,
} from "../../common";
import { MultipleChoiceOptionStringId } from "../../../types/forms/MultipleChoiceOptions";
import { ValidationResult } from "../../../types/forms";
import formValidationHelper from "../../../helpers/formValidationHelper";
import { SimpleFormRequiredBadgeMode } from "../../../types/simple-forms";
import { simpleFormHelper } from "../../../helpers";
import SignedInUserDto from "../../../types/dtos/users/SignedInUserDto";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";
import SimpleFormQuestionMoreInfo from "./SimpleFormQuestionMoreInfo";
import SmartTextArea from "../../common/SmartTextArea";

const inputCommonClassNames =
  "block p-2 bg-gray-100 border-0 text-sm disabled:cursor-not-allowed rounded-md focus:outline-none focus:ring-0 disabled:text-gray-600";

const longTextFieldDefaultMaxLength = 4000; // Same as db column max length

/** A component for rendering a single SimpleFormQuestion in edit mode.
 * Answer state is intended to be kept above this component, and passed in as a prop,
 * with local state pushing up via `onChange` on debounced value change.
 */
interface EditableSimpleFormQuestionProps {
  /** Determines whether to use second-person text or third-person */
  loggedInUser: SignedInUserDto;
  subjectUser: BaseUserDetailsDto;
  question: SimpleFormQuestion;
  answer: SimpleFormAnswer | null;
  validationErrors: SimpleFormValidationError[] | null;
  requiredBadgeMode: SimpleFormRequiredBadgeMode;
  onChange(newAnswer: SimpleFormAnswer): void;
  isFirstQuestionWithError?: boolean;
}

export const EditableSimpleFormQuestion = ({
  loggedInUser,
  subjectUser,
  question,
  answer,
  validationErrors,
  requiredBadgeMode,
  onChange,
}: EditableSimpleFormQuestionProps) => {
  const questionRef = useRef<null | HTMLDivElement>(null);

  var questionErrors =
    validationErrors?.filter((e) => e.questionId === question.questionId) ||
    null;

  //-- State
  const [localAnswer, setLocalAnswer] = useState<SimpleFormAnswer | null>(null);
  const [showMoreInfoPanel, setShowMoreInfoPanel] = useState<boolean>(false);

  //-- Setup

  useEffectOnce(() => {
    const answerObj: SimpleFormAnswer = answer || {
      questionId: question.questionId,
      textAnswer: null,
      multiChoiceAnswers: null,
    };
    setLocalAnswer(answerObj);
  });

  useEffect(() => {
    if (!questionRef?.current) return;

    var isThisQuestionFirstWithAnError =
      validationErrors &&
      validationErrors[0]?.questionId === question.questionId;

    if (questionRef.current && isThisQuestionFirstWithAnError) {
      questionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors]);

  //-- Events
  const toggleShowMoreInfoPanel = () => {
    setShowMoreInfoPanel(!showMoreInfoPanel);
  };

  const handleTextChange = (newValue: string) => {
    if (!localAnswer) return; // Should never happen, object should exist from the useEffectOnce
    const newAnswer: SimpleFormAnswer = {
      ...localAnswer,
      textAnswer: {
        answerId: localAnswer?.textAnswer?.answerId,
        answerText: newValue,
      },
    };
    setLocalAnswer(newAnswer);
    debounceAnswerChange(newAnswer);
  };

  const handleMultiChoiceChange = (
    newOptions: MultipleChoiceOptionStringId[]
  ) => {
    if (!localAnswer) return; // Should never happen, object should exist from the useEffectOnce
    const answerMultiChoiceOptions: SimpleFormMultipleChoiceAnswer[] =
      newOptions
        .filter((o) => o.isSelected)
        .map((o) => {
          return {
            selectedOptionId: o.optionId,
            customOtherText: o.customText,
          };
        });
    const newAnswer: SimpleFormAnswer = {
      ...localAnswer,
      multiChoiceAnswers: answerMultiChoiceOptions,
    };
    setLocalAnswer(newAnswer);
    debounceAnswerChange(newAnswer);
  };

  /** This calls `onChange` but debounced, to prevent calling it on every keypress in a text area etc */
  const debounceAnswerChange = useMemo(
    () => debounce(onChange, 500),
    [question, onChange]
  );

  //-- Render values

  // Use the third person text if the user is not the subject and the question has different text for third person
  const questionLabelText = simpleFormHelper.getQuestionText(
    question,
    subjectUser,
    loggedInUser
  );

  // Convert the validation errors to the type needed for the form components
  const isValid = (questionErrors?.length ?? 0) === 0;
  const validationResult: ValidationResult = {
    isValid: isValid,
    errors: isValid
      ? []
      : questionErrors!.map((err) =>
        formValidationHelper.convertValidationErrorType(err, question)
      ),
  };

  // Create a unique id for the input field (so the label can be associated with it)
  const htmlInputId = `esf-question-${question.questionId}`;

  // Map the question options to the type needed for the form components
  const multiChoiceOptions: MultipleChoiceOptionStringId[] =
    question.options.map((o) => {
      const optionAnswer = localAnswer?.multiChoiceAnswers?.find(
        (a) => a.selectedOptionId === o.optionId
      );

      return {
        optionId: o.optionId,
        allowCustomText: o.showCustomValueTextbox,
        isSelected: optionAnswer !== undefined,
        text: o.translateDisplayValue
          ? t(o.displayValueTranslationKeyIdentifier!)
          : o.rawDisplayValue!,
        value: o.underlyingStringValue || o.underlyingNumericValue!,
        customText: o.showCustomValueTextbox
          ? optionAnswer?.customOtherText
          : undefined,
        translateDisplayValue: o.translateDisplayValue,
        rawDisplayValue: o.rawDisplayValue,
        displayValueTranslationKeyIdentifier:
          o.displayValueTranslationKeyIdentifier,
        categoryId: o.categoryId,
      };
    });

  const allMultiChoiceOptionsDisplayTextAreNumeric = multiChoiceOptions.every(
    (o) => !isNaN(Number(o.text))
  );

  let inputElement: JSX.Element | null = null;
  switch (question.questionType) {
    case "SHORT-TEXT":
      inputElement = (
        <TextInput
          inputId={htmlInputId}
          className={cx(inputCommonClassNames, "w-full")}
          onChange={handleTextChange}
          value={
            localAnswer?.textAnswer?.answerText
              ? localAnswer?.textAnswer?.answerText
              : undefined
          }
          showValidationErrors={!validationResult.isValid}
          validationResult={validationResult}
          placeholder={
            question.placeholderText ? t(question.placeholderText) : undefined
          }
        />
      );
      break;
    case "LONG-TEXT":
      inputElement = (
        <SmartTextArea
          inputId={htmlInputId}
          className={cx(inputCommonClassNames, "w-full")}
          onChange={handleTextChange}
          value={
            localAnswer?.textAnswer?.answerText
              ? localAnswer?.textAnswer?.answerText
              : undefined
          }
          showValidationErrors={!validationResult.isValid}
          validationResult={validationResult}
          placeholder={
            question.placeholderText ? t(question.placeholderText) : undefined
          }
          maxLength={
            question.validationMax && question.validationMax > 0
              ? question.validationMax
              : longTextFieldDefaultMaxLength
          }
        />
      );
      break;
    case "DROPDOWN":
      inputElement = (
        <FormDropDownList<MultipleChoiceOptionStringId>
          values={multiChoiceOptions}
          onChange={handleMultiChoiceChange}
          showValidationErrors={!validationResult.isValid}
          validationResult={validationResult}
          className={cx(inputCommonClassNames, "w-1/2")}
        />
      );
      break;
    case "CHECKBOX":
      inputElement = (
        <CheckBoxList<MultipleChoiceOptionStringId>
          values={multiChoiceOptions}
          onChange={handleMultiChoiceChange}
          displayMode="vertical"
          uniqueFieldName={htmlInputId}
          selectMinCount={question.validationMin}
          selectMaxCount={question.validationMax}
          showValidationErrors={!validationResult.isValid}
          validationResult={validationResult}
          categories={question.categories}
        />
      );
      break;
    case "RADIO":
      inputElement = (
        <RadioButtonGroup<MultipleChoiceOptionStringId>
          values={multiChoiceOptions}
          onChange={handleMultiChoiceChange}
          uniqueFieldName={htmlInputId}
          showValidationErrors={!validationResult.isValid}
          validationResult={validationResult}
          categories={question.categories}
          containerClassNames=""
        />
      );
      break;
    case "SLIDER":
      inputElement = (
        <Slider
          scaleOptions={multiChoiceOptions}
          onChange={handleMultiChoiceChange}
          mainColourClassName="bg-gray-600"
          selectedValueDisplayMode={
            allMultiChoiceOptionsDisplayTextAreNumeric ? "NUMERIC" : "WORD"
          }
          showValidationErrors={!validationResult.isValid}
          validationResult={validationResult}
        />
      );
      break;
    default:
      // Unknown question type
      break;
  }

  return (
    <div
      ref={questionRef}
      className={`esf-question-container py-2 ${!validationResult.isValid ? "invalid" : ""
        }`}
    >
      <div className="esf-question-label">
        <Label
          text={questionLabelText}
          htmlFor={htmlInputId}
          className="font-medium text-base text-gray-700"
        />
        {question.isRequired && requiredBadgeMode === "SHOW-REQUIRED" && (
          <Badge
            text="Required"
            textColourClassName="text-gray-600"
            marginClassName="ml-2"
          />
        )}
        {!question.isRequired && requiredBadgeMode === "SHOW-OPTIONAL" && (
          <Badge
            text="Optional"
            textColourClassName="text-gray-600"
            marginClassName="ml-2"
          />
        )}
        <SimpleFormQuestionMoreInfo
          isReadOnly={false}
          hasPopupType={
            question.popupType === "HTML-CONTENT" ||
            question.popupType === "LINK-TO-NEW-TAB"
          }
          popupType={question.popupType}
          popupTriggerText={question.popupTriggerText}
          popupUrlDestination={question.popupTriggerValue}
          showMoreInfoPanel={showMoreInfoPanel}
          toggleShowMoreInfoPanel={toggleShowMoreInfoPanel}
        />
      </div>
      {showMoreInfoPanel && question.popupTriggerValue && (
        <div className="esf-question-more-info-panel">
          {parse(t(question.popupTriggerValue))}
        </div>
      )}
      <div className="esf-question-input mt-1">{inputElement}</div>
    </div>
  );
};
