import { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { UserTaskDetail } from "../../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { ClientTaskTypeCategory } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { t } from "i18next";
import SimpleFormQuestionMoreInfo from "../../../simple-forms/editable/SimpleFormQuestionMoreInfo";
import { advancedTaskHelper, dateHelper } from "../../../../helpers";
import { Label } from "../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import SimpleForm from "../../../simple-forms/editable/SimpleForm";
import UserContext from "../../../../state/UserContext";
import { TaskDetailSectionType } from "../../../../types/dtos/tasks/advanced-tasks/TaskDetailSectionType";
import TaskCommentsListReadOnly from "../comments/TaskCommentsListReadOnly";
import UserTaskComment from "../../../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import { useAuth } from "react-oidc-context";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";

interface TaskDetailsSectionProps {
  categories: ClientTaskTypeCategory[];
  taskType: ClientTaskType;
  details: UserTaskDetail;
  viewType: TaskDetailSectionType;
  showComments?: boolean;
}

const TaskDetailsSection = ({
  categories,
  taskType,
  details,
  viewType,
  showComments = false,
}: TaskDetailsSectionProps) => {
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);

  const [showTypeMoreInfoPanel, setShowTypeMoreInfoPanel] =
    useState<boolean>(false);
  const [showDescriptionMoreInfoPanel, setShowDescriptionMoreInfoPanel] =
    useState<boolean>(false);

  const [showMoreDetailsSection, setShowMoreDetailsSection] =
    useState<boolean>(false);

  const [comments, setComments] = useState<UserTaskComment[]>([]);

  useEffect(() => {
    if (!showComments || !details.userTaskId) {
      setComments([]);
      return;
    }

    tasksApi.listComments(
      details.userTaskId,
      (data: UserTaskComment[]) => {
        setComments(data);
      },
      (error: any) => {
        console.error("Error getting task details", error);
      }
    );
  }, [details.userTaskId]);

  const titleTranslationText =
    taskType.titleTranslationKeyIdentifier !== null
      ? t(taskType.titleTranslationKeyIdentifier)
      : t("TaskType.Popup.Labels.Description");

  const selectedCategoryNameTranslationIdentifier = categories.find(
    (c) => c.categoryId === details.categoryId
  )?.nameTranslationKeyIdentifier;

  // Only show the title field if there is no automatic title setting question in the body form
  const showTitle =
    details.bodyFormInstance.form !== null &&
    advancedTaskHelper.getAutomaticTitleSettingQuestion(
      taskType,
      details.bodyFormInstance.form
    ) === null;

  const toggleShowTypeMoreInfoPanel = () => {
    setShowTypeMoreInfoPanel(!showTypeMoreInfoPanel);
  };

  const toggleShowDescriptionMoreInfoPanel = () => {
    setShowDescriptionMoreInfoPanel(!showDescriptionMoreInfoPanel);
  };

  const toggleShowMoreDetailsSection = () => {
    setShowMoreDetailsSection(!showMoreDetailsSection);
  };

  const commentsComponent =
    comments.length === 0 ? null : (
      <>
        <hr className="my-3" />
        <TaskCommentsListReadOnly comments={comments} />
      </>
    );

  let moreDetailsSection: JSX.Element;
  switch (viewType) {
    case "BODY-AND-CLOSE-FORM":
      moreDetailsSection = (
        <>
          {/* Body Form */}
          <SimpleForm
            answers={details.bodyFormInstance.response.answers}
            formContent={details.bodyFormInstance.form}
            loggedInUser={userContext.user}
            subjectUser={details.ownerEmployee}
            validationErrors={null}
            onChange={() => {}}
            requiredBadgeMode="SHOW-OPTIONAL"
            isReadOnly={true}
          />
          {/* Close Form */}
          {details.closeFormInstance && (
            <>
              <hr className="my-3" />
              {details.closeFormInstance.response.lastModifiedByEmployeeName &&
                details.closeFormInstance.response.dateLastModified && (
                  <span>
                    {t("TaskType.Popup.TaskDetailsSection.FollowingResponses", {
                      user: details.closeFormInstance.response
                        .lastModifiedByEmployeeName,
                      date: dateHelper
                        .convertUtcDateToLocal(
                          details.closeFormInstance.response.dateLastModified
                        )
                        .toLocaleDateString(undefined),
                    })}
                    :
                  </span>
                )}
              <SimpleForm
                answers={details.closeFormInstance.response.answers}
                formContent={details.closeFormInstance.form}
                loggedInUser={userContext.user}
                subjectUser={details.ownerEmployee}
                validationErrors={null}
                onChange={() => {}}
                requiredBadgeMode="SHOW-OPTIONAL"
                isReadOnly={true}
              />
            </>
          )}
        </>
      );
      break;
    case "BODY-FORM-ONLY":
    default:
      moreDetailsSection = (
        <SimpleForm
          answers={details.bodyFormInstance.response.answers}
          formContent={details.bodyFormInstance.form}
          loggedInUser={userContext.user}
          subjectUser={details.ownerEmployee}
          validationErrors={null}
          onChange={() => {}}
          requiredBadgeMode="SHOW-OPTIONAL"
          isReadOnly={true}
        />
      );
      break;
  }

  return (
    <div className="text-sm flex flex-col">
      <div className="flex flex-row gap-4">
        {/* Show dropdown if there are categories to render  */}
        {categories.length > 0 && (
          // Type Field
          <div className="w-1/2 py-2">
            <div className="esf-question-label">
              <Label
                text={t("TaskType.Popup.Labels.Type")}
                className="text-gray-700 text-base font-semibold"
              />
              {(taskType.categoryPopupTranslationKeyIdentifier ||
                taskType.categoryPopupDestinationUrl) && (
                <SimpleFormQuestionMoreInfo
                  isReadOnly={true}
                  hasPopupType={false}
                  popupTriggerText="TaskType.Popup.Tooltip.Type"
                  popupHtmlContent={
                    taskType.categoryPopupTranslationKeyIdentifier
                  }
                  popupUrlDestination={taskType.categoryPopupDestinationUrl}
                  showMoreInfoPanel={showTypeMoreInfoPanel}
                  toggleShowMoreInfoPanel={toggleShowTypeMoreInfoPanel}
                />
              )}
            </div>
            <div>
              {selectedCategoryNameTranslationIdentifier && (
                <div className="text-gray-600 block w-full border-0 text-sm rounded-md mb-2">
                  {t(selectedCategoryNameTranslationIdentifier)}
                </div>
              )}
            </div>
          </div>
        )}
        {/* Due Date Field */}
        <div className="w-1/2 py-2">
          <Label
            text={t("TaskType.Popup.Labels.DueDate")}
            className="text-gray-700 text-base font-semibold"
          />
          <div>
            {details.targetDate && (
              <div className="text-gray-600 block w-full border-0 text-sm rounded-md mb-2">
                {dateHelper
                  .convertUtcDateToLocal(details.targetDate)
                  .toLocaleDateString(undefined)}
              </div>
            )}
          </div>
        </div>
      </div>
      {showTypeMoreInfoPanel &&
        taskType.categoryPopupTranslationKeyIdentifier && (
          <div className="esf-question-more-info-panel-readonly">
            {parse(t(taskType.categoryPopupTranslationKeyIdentifier))}
          </div>
        )}
      {/* Title/Description Field*/}
      {showTitle && (
        <div className="py-2">
          <div className="esf-question-label">
            <Label
              text={titleTranslationText}
              className="text-gray-700 text-base font-semibold"
            />
            {(taskType.titlePopupTranslationKeyIdentifier ||
              taskType.titlePopupDestinationUrl) && (
              <SimpleFormQuestionMoreInfo
                isReadOnly={true}
                hasPopupType={false}
                popupTriggerText="TaskType.Popup.Tooltip.Description"
                popupHtmlContent={taskType.titlePopupTranslationKeyIdentifier}
                popupUrlDestination={taskType.titlePopupDestinationUrl}
                showMoreInfoPanel={showDescriptionMoreInfoPanel}
                toggleShowMoreInfoPanel={toggleShowDescriptionMoreInfoPanel}
              />
            )}
          </div>
          {showDescriptionMoreInfoPanel &&
            taskType.titlePopupTranslationKeyIdentifier && (
              <div className="esf-question-more-info-panel-readonly">
                {parse(t(taskType.titlePopupTranslationKeyIdentifier))}
              </div>
            )}
          <div>
            {details.title && (
              <div className="text-gray-600 block w-full border-0 text-sm rounded-md mb-2">
                {details.title}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Show more details */}
      {details.bodyFormInstance && (
        <div className="mb-1">
          {!showMoreDetailsSection && (
            <div className="flex flex-row">
              <div className="w-3/12 md:w-1/3">
                <hr className="mt-3" />
              </div>
              <div className="w-6/12 md:w-1/3 text-center">
                <button
                  onClick={toggleShowMoreDetailsSection}
                  className="show-more-btn text-xs md:text-sm font-semibold p-1"
                >
                  <FontAwesomeIcon icon={faPlus} size="xs" className="pr-1" />
                  {t(
                    comments.length > 0
                      ? "TaskType.Popup.CloseView.SeeFullTaskDetailsAndComments"
                      : "TaskType.Popup.CloseView.SeeFullTaskDetails"
                  )}
                </button>
              </div>
              <div className="w-3/12 md:w-1/3">
                <hr className="mt-3" />
              </div>
            </div>
          )}

          {showMoreDetailsSection && (
            <div>
              {moreDetailsSection}

              {commentsComponent}
              <hr className="mt-3" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskDetailsSection;
