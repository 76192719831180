import { t } from "i18next";
import AdvancedTaskDto from "../types/dtos/forms/AdvancedTaskDto";
import { HighlightedAdvancedTasks } from "../types/dtos/tasks/advanced-tasks/HighlightedAdvancedTasksCount";
import { TaskManagementDropDownMenuItemDto } from "../types/tasks/TaskManagementDropDownMenuItemDto";
import { TaskManagementDropDownMenuArgs } from "../types/tasks/TaskManagementDropDownMenuArgs";
import { ClientTaskType } from "../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { UserContextInterface } from "../state/UserContext";
import { FormType, ValidationSettings } from "../types/forms";
import { CountRestrictionType } from "../types/dtos/tasks/advanced-tasks/CountRestrictionType";
import {
  SimpleFormAnswer,
  SimpleFormContent,
  SimpleFormQuestion,
} from "../types/dtos/simple-forms";
import AdvancedTaskQuestionMode from "../types/dtos/forms/AdvancedTaskQuestionMode";

export const advancedTaskHelper = {
  /* Method to retrieve the translated lower case text of singular task name **/
  ToLowerCase: (translationKeyIdentifier: string): string => {
    const translatedText = t(translationKeyIdentifier);

    if (translatedText !== translatedText.toUpperCase()) {
      return translatedText.toLowerCase();
    }

    return translatedText;
  },

  /** Use the TaskType configuration, and the question validation settings, to determine the validation count for the number of tasks allowed to be created.
   * Sometimes clients don't want the dashboard min/max, but do want to enforce at least one task, or a maximum of 3 tasks, etc in a collab doc/journey.
   */
  getValidationCounts(
    /** The min count defined against the TaskType */
    taskTypeMinCount: number | null,
    /** The max count defined against the TaskType */
    taskTypeMaxCount: number | null,
    questionValidations: ValidationSettings
  ): {
    min: number | null;
    max: number | null;
    restrictionType: CountRestrictionType;
  } {
    // By default, use the task type config for min/max
    let min = taskTypeMinCount;
    let max = taskTypeMaxCount;
    let restrictionType: CountRestrictionType = "NONE";

    // If question-level validations are specified, override the task type config for min/max active tasks
    if (questionValidations) {
      if (questionValidations.min != null && questionValidations.min >= 0) {
        min = questionValidations.min;
      }
      if (questionValidations.max != null && questionValidations.max > 0) {
        max = questionValidations.max;
      }
    }

    // Calculate the restriction type
    if (min && max) {
      restrictionType = "MIN-AND-MAX";
    } else if (min && !max) {
      restrictionType = "MIN-ONLY";
    } else if (!min && max) {
      restrictionType = "MAX-ONLY";
    }

    return {
      min,
      max,
      restrictionType,
    };
  },

  /** Creates a copy of the current highlights minus the task to be un-highlighted */
  removeTaskHighlightForUserContext: (
    loggedInUserId: number,
    taskOwnerUserId: number,
    taskType: string,
    taskId: string,
    currentHighlights: HighlightedAdvancedTasks[]
  ): HighlightedAdvancedTasks[] => {
    if (!currentHighlights) {
      return [];
    }
    const output: HighlightedAdvancedTasks[] = [...currentHighlights];
    const matchingTaskTypeIndex = output.findIndex(
      (x) => x.taskTypeId === taskType
    );

    if (matchingTaskTypeIndex >= 0) {
      if (loggedInUserId === taskOwnerUserId) {
        // The task to be cleared is one owned by the logged in user
        const ownHighlightedTaskIds = output[matchingTaskTypeIndex].ownTaskIds;
        const matchingTaskIdIndex = ownHighlightedTaskIds.findIndex(
          (x) => x === taskId
        );
        if (matchingTaskIdIndex >= 0) {
          ownHighlightedTaskIds.splice(matchingTaskIdIndex, 1);
        }
      } else {
        // The task to be cleared belongs to someone managed by the logged in user
        const managerHighlightedTaskIds =
          output[matchingTaskTypeIndex].managerTaskIds;
        const matchingTaskIdIndex = managerHighlightedTaskIds.findIndex(
          (x) => x === taskId
        );
        if (matchingTaskIdIndex >= 0) {
          managerHighlightedTaskIds.splice(matchingTaskIdIndex, 1);
        }
      }
    }

    return output;
  },
  /* Gets the drop down menu options for the 'Actions' button for the TaskManagementQuestion **/
  getDropDownMenuOptions: (
    task: AdvancedTaskDto,
    taskType: ClientTaskType | undefined,
    formType: FormType,
    commentsRequired: boolean,
    questionMode: AdvancedTaskQuestionMode
  ): TaskManagementDropDownMenuItemDto[] => {
    const menuItems: TaskManagementDropDownMenuItemDto[] = [];

    const questionModeIsTaskReview =
      questionMode === "TASK-REVIEW-ONLY" ||
      questionMode === "TASK-REVIEW-AND-CREATION";

    // [Comment on progress] - Only show when the task is not cancelled
    // and the question type isn't a review only (as in this scenario the user should be signposted
    // towards either closing or cancelling the task)
    if (task.status !== "CANCELLED" && questionMode !== "TASK-REVIEW-ONLY") {
      menuItems.push({
        text: t("TaskType.Common.CommentOnProgress"),
        customArg: TaskManagementDropDownMenuArgs.AddComment,
        disabled: false,
      });
    }

    // [View details / Edit details]
    menuItems.push({
      text:
        task.status === "COMPLETED" ||
        task.status === "CANCELLED" ||
        questionModeIsTaskReview
          ? t("Common.ViewDetails")
          : t("Common.EditDetails"),
      customArg: TaskManagementDropDownMenuArgs.ViewDetails,
      disabled: false,
    });

    if (task.status === "ACTIVE" && taskType !== undefined) {
      const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
        taskType.singularNameTranslationKeyIdentifier
      );
      // [Complete Task] - Only show when the task is active and there is a taskType (as we need the name)
      menuItems.push({
        text: t("TaskType.Common.CompleteTask", {
          taskType: singularTaskTypeName,
        }),
        customArg: TaskManagementDropDownMenuArgs.CompleteTask,
        disabled: false,
      });

      // [Cancel Task] - Only show when the task is active and there is a taskType (as we need the name)
      menuItems.push({
        text: t("TaskType.Common.CancelTask", {
          taskType: singularTaskTypeName,
        }),
        customArg: TaskManagementDropDownMenuArgs.CancelTask,
        disabled: false,
      });
    }

    // [Re-open Task] - Only show when the task has been completed or cancelled and there is a taskType (as we need the name)
    if (
      (task.status == "COMPLETED" || task.status == "CANCELLED") &&
      taskType !== undefined
    ) {
      const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
        taskType.singularNameTranslationKeyIdentifier
      );
      menuItems.push({
        text: t("TaskType.Common.ReopenTask", {
          taskType: singularTaskTypeName,
        }),
        customArg: TaskManagementDropDownMenuArgs.ReopenTask,
        disabled: false,
      });
    }

    // [Review and rate Task] - Only show when the task is completed AND the owner and manager have
    // commented AND taskType has a review form AND only show in the collab doc (employees shouldn't
    // rate before a convo has taken place)
    if (
      task.status === "COMPLETED" &&
      taskType !== undefined &&
      taskType.reviewFormId !== null &&
      formType === "COLLAB-DOC" &&
      (!commentsRequired ||
        (task.enforcedComments != null &&
          task.enforcedComments.areOwnerCountConditionsSatisfied &&
          task.enforcedComments.areManagerRoleCountConditionsSatisfied))
    ) {
      const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
        taskType.singularNameTranslationKeyIdentifier
      );
      menuItems.push({
        text: t("TaskType.Common.ReviewAndRateTask", {
          taskType: singularTaskTypeName,
        }),
        customArg: TaskManagementDropDownMenuArgs.ReviewTask,
        disabled: false,
      });
    }

    return menuItems;
  },

  taskIsHighlightedForUser: (
    userContext: UserContextInterface,
    task: AdvancedTaskDto,
    isReadOnly: boolean
  ): boolean => {
    // If the journey/collab doc has been completed and is in read only then don't show the highlight dot
    // as they won't be able to clear it from here as the buttons don't show
    if (isReadOnly) {
      return false;
    }

    if (task.highlightToOwner && task.ownerEmployeeId === userContext.user.id) {
      return true;
    }

    if (
      task.highlightToManager &&
      task.ownerEmployeeId !== userContext.user.id
    ) {
      return true;
    }

    return false;
  },

  /** Returns the AutomaticTitleSettingQuestion if one is specified against the TaskType */
  getAutomaticTitleSettingQuestion: (
    clientTaskType: ClientTaskType,
    formContent: SimpleFormContent
  ): SimpleFormQuestion | null => {
    if (
      formContent?.questions &&
      clientTaskType.titleSettingQuestionId !== null
    ) {
      const titleQuestion = formContent.questions.find(
        (x) => x.questionId === clientTaskType.titleSettingQuestionId
      );

      if (titleQuestion) {
        return titleQuestion;
      }
    }
    return null;
  },

  /** If a titleSettingQuestion is specified against the TaskType, look for an answer to that question, and populate the UserTask's Title field with that value.
   * Returns true if the title was updated, false if it was not.
   */
  conditionallyAutomaticallySetTaskTitle: (
    clientTaskType: ClientTaskType,
    formContent: SimpleFormContent,
    latestAnswers: SimpleFormAnswer[],
    currentTitle: string | undefined,
    onSetTaskTitle: (newTitle: string) => void
  ): boolean => {
    const titleSettingQuestion =
      advancedTaskHelper.getAutomaticTitleSettingQuestion(
        clientTaskType,
        formContent
      );
    if (titleSettingQuestion) {
      const answer = latestAnswers.find(
        (x) => x.questionId === titleSettingQuestion.questionId
      );
      if (
        answer &&
        answer.multiChoiceAnswers &&
        answer.multiChoiceAnswers.length > 0
      ) {
        // Find the multi choice answer value
        const multiChoiceAnswer = answer.multiChoiceAnswers[0];

        // Use the "Custom" text if it exists, otherwise use the selected option's display value (translated, where relevant)
        let answerDisplayValue: string | null = null;
        if (
          multiChoiceAnswer.customOtherText &&
          multiChoiceAnswer.customOtherText.length > 0
        ) {
          answerDisplayValue = multiChoiceAnswer.customOtherText;
        } else {
          const option = titleSettingQuestion.options?.find(
            (x) => x.optionId === multiChoiceAnswer.selectedOptionId
          );
          if (option) {
            answerDisplayValue =
              option.translateDisplayValue &&
              option.displayValueTranslationKeyIdentifier
                ? t(option.displayValueTranslationKeyIdentifier)
                : option.rawDisplayValue;
          }
        }

        const backupValue = "[Not set]"; // Logic dictates this should never be necessary, but this easily traceable value will help us debug if it ever happens
        const newTitleValue = answerDisplayValue
          ? answerDisplayValue
          : backupValue;
        if (newTitleValue !== currentTitle) {
          onSetTaskTitle(newTitleValue);
          return true;
        }
      }
    }

    return false;
  },
};

export default advancedTaskHelper;
