import AdvancedTaskDto from "../../../../types/dtos/forms/AdvancedTaskDto";
import TaskManagementConfigDto from "../../../../types/dtos/forms/TaskManagementConfigDto";
import { t } from "i18next";
import TaskStatusBadge from "./TaskStatusBadge";
import cx from "classnames";
import { UserContextInterface } from "../../../../state/UserContext";
import { DropDownMenu, HighlightDot } from "../../../common";
import { TaskManagementDropDownMenuArgs } from "../../../../types/tasks/TaskManagementDropDownMenuArgs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { advancedTaskHelper } from "../../../../helpers";
import EnforcedCommentsSection from "./EnforcedCommentsSection";
import { FormType } from "../../../../types/forms";

interface AdvancedTaskDesktopItemProps {
  userContext: UserContextInterface;
  task: AdvancedTaskDto;
  taskType: ClientTaskType | undefined;
  config: TaskManagementConfigDto;
  isCancelled?: boolean;
  isReadOnly: boolean;
  formType: FormType;
  displayRatingColumn: boolean;
  columnCount: number;
  loggedInUserId: number;
  isLoggedInUserTheSubjectUser: boolean;
  subjectUsersName: string;
  nonSubjectUsersName: string;
  onDropdownMenuItemClick: (userTaskId: string, action: string) => void;
  onViewButtonClick: (userTaskId: string, snapshotDate: Date) => void;
}

function AdvancedTaskDesktopItem({
  userContext,
  task,
  taskType,
  config,
  isCancelled = false,
  isReadOnly,
  formType,
  displayRatingColumn,
  columnCount,
  loggedInUserId,
  isLoggedInUserTheSubjectUser,
  subjectUsersName,
  nonSubjectUsersName,
  onDropdownMenuItemClick,
  onViewButtonClick,
}: AdvancedTaskDesktopItemProps) {
  /// Show enforced comments section if its enabled and isn't for cancelled tasks
  const showEnforceCommentsSection =
    config.enforceComments == true &&
    task.enforcedComments != null &&
    isCancelled == false &&
    (config.answerSetGuidId == null ||
      task.createdInAnswerSetGuidId != config.answerSetGuidId);

  const ratingIsClickable = !isCancelled && !isReadOnly && task.rating != null;

  const onDropDownItemClick = (index: number, action: string) => {
    onDropdownMenuItemClick(task.taskId, action);
  };

  const onRatingClick = () => {
    if (ratingIsClickable) {
      // Task could come from either ReviewForm OR CloseForm, so try to direct to the most relevant
      if (task.hasReviewFormResponse) {
        onDropdownMenuItemClick(
          task.taskId,
          TaskManagementDropDownMenuArgs.ReviewTask
        );
      } else {
        onDropdownMenuItemClick(
          task.taskId,
          TaskManagementDropDownMenuArgs.CompleteTask
        );
      }
    }
  };

  const onViewClickHandler = () => {
    if (task.snapshotDate != null) {
      onViewButtonClick(task.taskId, task.snapshotDate);
    }
  };

  const viewTaskDetailsPopup = () => {
    onDropDownItemClick(0, TaskManagementDropDownMenuArgs.ViewDetails);
  };

  const btnDropDownContents = (
    <div>
      <button
        id="adv-task-table-item-option"
        className="text-[#5d5d5d] bg-[#F5F5F5] hover:bg-gray-200 font-medium rounded-sm py-1 px-4 border border-gray-300 text-xs w-full lg:inline-block lg:w-auto"
      >
        {t("ManagerDashboard.Common.Actions")}
        <FontAwesomeIcon icon={faChevronDown} className="pl-2" />
      </button>
    </div>
  );

  return (
    <>
      <tr
        className={cx(
          "bg-white",
          showEnforceCommentsSection == false ? "border-b" : ""
        )}
      >
        <th
          scope="row"
          className={cx(
            "px-6 font-medium whitespace-nowrap sm:whitespace-normal",
            isCancelled ? "line-through text-gray-300" : "text-gray-900",
            showEnforceCommentsSection ? "py-2" : "py-4"
          )}
        >
          {advancedTaskHelper.taskIsHighlightedForUser(
            userContext,
            task,
            isReadOnly
          ) && <HighlightDot containerClassNames="mr-1" />}
          <button
            className="hover:cursor-pointer"
            onClick={viewTaskDetailsPopup}
          >
            {task.title}
          </button>
        </th>
        <td className="text-center px-6 py-4">
          <TaskStatusBadge
            status={task.status}
            isNewlyCreatedInThisJourney={task.isNewlyCreatedInThisJourney}
            isOverdue={task.isOverdue}
          />
        </td>
        {displayRatingColumn && (
          <td
            onClick={onRatingClick}
            className={cx(
              "text-center px-6 py-4",
              ratingIsClickable ? "hover:cursor-pointer hover:underline" : ""
            )}
          >
            {isCancelled && <>N/A</>}
            {!isCancelled && (
              <>
                {task.rating == null
                  ? "-"
                  : `${task.rating}/${config.ratingQuestionMaxValue}`}
              </>
            )}
          </td>
        )}
        {/* Not Read-only - Show actions button */}
        {!isReadOnly && (
          <td className="text-center px-6 py-4">
            <DropDownMenu
              eventType="EVENT"
              items={advancedTaskHelper.getDropDownMenuOptions(
                task,
                taskType,
                formType,
                showEnforceCommentsSection,
                config.questionMode
              )}
              onItemClick={onDropDownItemClick}
              customButtonContents={btnDropDownContents}
              menuButtonClassName="cursor-pointer"
            />
          </td>
        )}
        {/* Read-only - Show view button */}
        {isReadOnly && (
          <td className="text-center px-6 py-4">
            <button
              className="text-[#5d5d5d] bg-[#F5F5F5] hover:bg-gray-200 font-medium rounded-sm py-1 px-4 border border-gray-300 text-xs w-full lg:inline-block lg:w-auto"
              onClick={onViewClickHandler}
            >
              {t("Common.View")}
            </button>
          </td>
        )}
      </tr>

      {showEnforceCommentsSection && (
        <tr className="bg-white border-b">
          <td colSpan={columnCount}>
            <EnforcedCommentsSection
              task={task}
              taskType={taskType!}
              formType={formType}
              status={task.status}
              details={task.enforcedComments}
              contentClassName="pl-6 pb-2"
              loggedInUserId={loggedInUserId}
              isLoggedInUserTheSubjectUser={isLoggedInUserTheSubjectUser}
              subjectUsersName={subjectUsersName}
              nonSubjectUsersName={nonSubjectUsersName}
              onItemAction={onDropdownMenuItemClick}
            />
          </td>
        </tr>
      )}
    </>
  );
}

export default AdvancedTaskDesktopItem;
