import { useTranslation } from "react-i18next";
import UserTaskListItemDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import SmallLoader from "../loaders/SmallLoader";
import EmptyTaskTabState from "./EmptyTaskTabState";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import TaskTabItem from "./TaskTabItem";
//@ts-ignore
import Pagination from "react-js-pagination";
import { UserContextInterface } from "../../state/UserContext";
import UserTaskListGroupDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListGroupDto";
import TaskRequirementBadge from "./TaskRequirementBadge";
import { ClientTaskTypeCategory } from "../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { CountRestrictionType } from "../../types/dtos/tasks/advanced-tasks/CountRestrictionType";
import { AdvancedTaskStatus } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { advancedTaskHelper } from "../../helpers";

interface TaskTabProps {
  userContext: UserContextInterface;
  tabType: AdvancedTaskStatus;
  isLoading: boolean;
  taskType: ClientTaskType;
  displayTasks: UserTaskListItemDto[];
  itemsPerPage: number;
  pageNumber: number;
  totalItemCount: number | undefined;
  userGroup?: UserTaskListGroupDto;
  disabledNewTaskButton?: boolean;
  allTasks: UserTaskListItemDto[];
  minTasks: number | null;
  maxTasks: number | null;
  countRestriction: CountRestrictionType;
  activeCategories: ClientTaskTypeCategory[] | null;
  isTeamTaskManagement: boolean;
  isActivelyInReview: boolean;
  onAddNewTaskClick: () => void;
  onExistingTaskClick: (userTaskId: string) => void;
  onPageNumberChange(pageNumber: number): void;
}

const TaskTab = ({
  userContext,
  tabType,
  isLoading,
  taskType,
  displayTasks,
  itemsPerPage,
  pageNumber,
  totalItemCount,
  userGroup,
  disabledNewTaskButton,
  allTasks,
  minTasks,
  maxTasks,
  countRestriction,
  activeCategories,
  isTeamTaskManagement,
  isActivelyInReview,
  onAddNewTaskClick,
  onExistingTaskClick,
  onPageNumberChange,
}: TaskTabProps) => {
  const { t } = useTranslation();
  const clientColour = userContext.user.client.primaryHexColour;
  const isActiveTabSelected = tabType === "ACTIVE";
  const isCompletedTabSelected = tabType === "COMPLETED";
  const isHistoricTabSelected = tabType === "HISTORIC";
  const doesUserHaveTaskRestrictions =
    minTasks != null ||
    maxTasks != null ||
    activeCategories?.find((x) => x.minTaskCount || x.maxTaskCount) != null;

  const hideAddNewButton = isActivelyInReview && taskType.hideAddButtonWhenInReview;

  return (
    <>
      {isLoading && (
        <div className="pt-6">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.Loading")}...
          </p>
        </div>
      )}
      {!isLoading && (
        <>
          {displayTasks == null ||
            (displayTasks.length === 0 && (
              <EmptyTaskTabState
                clientColour={clientColour}
                iconType={taskType.iconType}
                tabType={tabType}
                singularTaskTypeNameIdentifier={
                  taskType.singularNameTranslationKeyIdentifier
                }
                pluralTaskTypeNameIdentifier={
                  taskType.pluralNameTranslationKeyIdentifier
                }
                isActiveTabSelected={isActiveTabSelected}
                userGroup={userGroup}
                onAddNewTaskClick={onAddNewTaskClick}
              />
            ))}
          {displayTasks && displayTasks.length > 0 && (
            <>
              <div className="bg-[#F7F8FA] rounded-md px-5 py-3">
                {displayTasks.map((task) => {
                  const wasCompletedByLoggedInUser =
                    task.hasBeenCompleted &&
                    userContext.user.id === task.completedByEmployeeId;
                  return (
                    <TaskTabItem
                      key={task.userTaskId}
                      task={task}
                      wasCompletedByLoggedInUser={wasCompletedByLoggedInUser}
                      isActiveTabSelected={isActiveTabSelected}
                      isCompletedTabSelected={isCompletedTabSelected}
                      isHistoricTabSelected={isHistoricTabSelected}
                      onClickEvent={onExistingTaskClick}
                    />
                  );
                })}
              </div>
              <div className="grid grid-cols-1 pt-1 md:grid-cols-5 md:text-gray-500">
                <div className="col-span-3">
                  {/* Only show the pagination if there is more than one page (aka if the totalItemCount is greater than itemsPerPage) */}
                  {totalItemCount && totalItemCount > itemsPerPage && (
                    <div className="mt-2 text-xs">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalItemCount}
                        pageRangeDisplayed={5}
                        onChange={onPageNumberChange}
                        activeClass="pagination-active-list-item"
                        itemClass="pagination-list-item"
                        itemClassFirst="pagination-first-item"
                        itemClassLast="pagination-last-item"
                      />
                    </div>
                  )}
                </div>
                <div className="col-span-2 flex flex-row pt-1 justify-end">
                  {isActiveTabSelected && !hideAddNewButton && (
                    <>
                      {doesUserHaveTaskRestrictions && (
                        <div className="pt-1 mr-2">
                          <TaskRequirementBadge
                            tasks={allTasks}
                            minTasks={minTasks}
                            maxTasks={maxTasks}
                            countRestriction={countRestriction}
                            activeCategories={activeCategories}
                            taskType={taskType}
                            isTeamTaskManagement={isTeamTaskManagement}
                            userGroup={userGroup}
                            origin="OUTSIDE-JOURNEY"
                          />
                        </div>
                      )}
                      <p>
                        <button
                          disabled={disabledNewTaskButton}
                          className="btn-primary !px-3 !py-2 disabled:cursor-not-allowed"
                          onClick={onAddNewTaskClick}
                        >
                          <span className="ml-1">
                            {t("TaskType.Button.AddNewX").replace(
                              "#TASK_TYPE#",
                              advancedTaskHelper.ToLowerCase(
                                taskType.singularNameTranslationKeyIdentifier
                              )
                            )}
                          </span>
                        </button>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TaskTab;
