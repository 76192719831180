import { useCallback, useContext, useEffect, useState } from "react";
import { t } from "i18next";
import {
  Label,
  Slider,
  RadioButtonGroup,
  TextArea,
  CheckBoxList,
  FormDropDownList,
  Badge,
  SafeRenderHtml,
  TextInput,
} from "../common";
import {
  CheckBoxListDisplayMode,
  EnforcedCommentSaveErrorStatus,
  GoalReviewQuestionAnswerValue,
  QuestionAnswer,
  QuestionAnswerType,
  QuestionAnswerValue,
  QuestionTasks,
  ValidationResult,
} from "../../types/forms";
import FormQuestion from "../../types/forms/FormQuestion";
import JourneyCommentControl from "./JourneyCommentControl";
import multipleChoiceQuestionHelper from "../../helpers/multipleChoiceQuestionHelper";
import BehaviourQuestionAnswerValue from "../../types/forms/BehaviourQuestionAnswerValue";
import {
  dateHelper,
  questionTextHelper,
  questionTypeHelper,
} from "../../helpers";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import UserContext from "../../state/UserContext";
import React from "react";
import DevelopmentQuestion from "../forms/advanced/DevelopmentQuestion";
import DevelopmentQuestionAnswerValue from "../../types/forms/DevelopmentQuestionAnswerValue";
import GoalSettingQuestion from "../forms/advanced/GoalSettingQuestion";
import BehaviourMoreInfo from "../forms/advanced/behaviours/BehaviourMoreInfo";
import {
  BehaviourAttributeQuestion,
  GoalReviewQuestionStatusAndComment,
  MultipleBehaviourQuestion,
  TaskQuestion,
} from "../forms/advanced";
import { JourneyCommentDto } from "../../types/dtos/journeys";
import JourneyAnswerSaveIndicator from "./JourneyAnswerSaveIndicator";
import { EnforcedCommentType, FormComplexities } from "../../types/dtos/forms";
import MoreInfo from "../forms/advanced/MoreInfo";
import MultipleChoiceOptionNumericId from "../../types/forms/MultipleChoiceOptions";
import BehaviourSubtext from "../forms/advanced/behaviours/BehaviourSubtext";
import TaskManagementQuestion from "../forms/advanced/TaskManagementQuestion";
import AdvancedTaskDto from "../../types/dtos/forms/AdvancedTaskDto";

interface JourneyQuestionProps {
  question: FormQuestion;
  currentValue: QuestionAnswerValue;
  subjectUser: BaseUserDetailsDto;
  answerSetParticipants: BaseUserDetailsDto[];
  tasks: QuestionTasks | null;
  formComplexities: FormComplexities;
  /** Whether or not we're in Dual Prep prep mode */
  isInPrepMode: boolean;
  /** To handle updating the state for the value of an answer (doesn't trigger an API call) */
  onValueChange(
    newValue: QuestionAnswerValue,
    answerType: QuestionAnswerType,
    formId: number
  ): void;
  comments: JourneyCommentDto[];
  onCommentChange(
    newValue: string | null,
    onSaveSuccess: () => void,
    onSaveError: () => void
  ): void;
  onEnforcedCommentChange(
    newValue: string | null,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ): void;
  showValidationErrors: boolean;
  enableNextButton: boolean;
  showSubmitBtn: boolean;
  formColor: string;
  answerSetUniqueId: string | null | undefined;
  answerSetDateCreated: Date | null | undefined;
  onEnforcedCommentBlur(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    clientFormId: number,
    onSuccess: () => void,
    onError: () => void
  ): void;
  onNextPageClick(): void;
  /** When a use adds/edits/deletes a new task. Saves to state and then sends to the API */
  onChangeQuestionTasks(
    questionTasks: QuestionTasks,
    onSaveSuccess: () => void,
    onSaveError: () => void
  ): void;
  /** When wanting to save a question's answer via the API */
  onValueSave(
    answer: QuestionAnswer,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the answer state (e.g. on save failure) */
  onRetryValueSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for tasks (e.g. on save failure) */
  onRetryTasksSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for enforced comments (e.g. on save failure) */
  onRetryEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    clientFormId: number,
    onSuccess: () => void,
    onError: () => void
  ): void;
  onPreAdvancedTaskInteractionCheckForAnswerSet(
    onSaveSuccess: (
      answerSetUniqueId: string | null,
      answerSetDateCreated: Date | null
    ) => void,
    onSaveError: () => void
  ): void;
  onChangeAdvancedTasks(
    questionId: string,
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[],
    newTasks?: AdvancedTaskDto[]
  ): void;
}

/** This is the component which renders the correct question type based on the details passed in.
 * State is managed at the JourneyForm level.
 */
function JourneyQuestion({
  question,
  currentValue,
  subjectUser,
  comments,
  showValidationErrors,
  enableNextButton,
  showSubmitBtn,
  tasks,
  formColor,
  answerSetParticipants,
  formComplexities,
  isInPrepMode,
  answerSetUniqueId,
  answerSetDateCreated,
  onValueChange,
  onValueSave,
  onRetryValueSave,
  onRetryTasksSave,
  onRetryEnforcedCommentSave,
  onCommentChange,
  onEnforcedCommentChange,
  onEnforcedCommentBlur,
  onNextPageClick,
  onChangeQuestionTasks,
  onPreAdvancedTaskInteractionCheckForAnswerSet,
  onChangeAdvancedTasks,
}: JourneyQuestionProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingSucceeded, setSavingSucceeded] = useState<boolean>(false);
  const [answerSavingErrored, setAnswerSavingErrored] =
    useState<boolean>(false);
  const [tasksSavingErrored, setTasksSavingErrored] = useState<boolean>(false);
  const [
    enforcedCommentSavingErrorStatus,
    setEnforcedCommentSavingErrorStatus,
  ] = useState<EnforcedCommentSaveErrorStatus>({
    errored: false,
    behaviourId: null,
    goalId: null,
    nonStateValue: null,
  });

  // Multiple choice question setup
  const getMultipleChoiceOptions = (): MultipleChoiceOptionNumericId[] => {
    return multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
      question,
      currentValue,
      true
    );
  };

  // State and constants
  const inputIdAttributeValue = "journey_current_question";
  const usesAdvancedTaskTypes = userContext.user.client.taskTypes.length > 0;
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);

  const [multipleChoiceQuestionOptions, setMultipleChoiceQuestionOptions] =
    useState<MultipleChoiceOptionNumericId[]>(getMultipleChoiceOptions());

  const questionParticipants: UserBasicDetailsDto[] = answerSetParticipants.map(
    (x) => {
      return {
        ...x,
        initials: "", // Not used
        profileImageUrl: null,
        currentStatus: "UNKNOWN",
      };
    }
  );

  // Update the local multiple choice option state when the question or answer changes
  useEffect(() => {
    const newOptionState = getMultipleChoiceOptions();
    setMultipleChoiceQuestionOptions(newOptionState);
  }, [question, currentValue]);

  // Update the validation errors when the question, answer or tasks change
  useEffect(() => {
    const tasksToValidate = tasks !== null ? tasks.tasks : null;

    // If the question has taskManagementConfig and the answerSetGuidId is null, BUT there is
    // one available to it now... set it.
    if (
      question.taskManagementConfig != null &&
      question.taskManagementConfig.answerSetGuidId == null &&
      answerSetUniqueId != null
    ) {
      question.taskManagementConfig.answerSetGuidId = answerSetUniqueId;
    }

    setValidationResult(
      question.validate(
        currentValue,
        tasksToValidate,
        question.taskManagementConfig ?? null,
        comments,
        userContext.user.id,
        subjectUser.userId,
        "JOURNEY"
      )
    );
  }, [question, currentValue, tasks, comments]);

  // Events

  /** Get the selected option id(s) and update the state on the parent */
  const onMultipleChoiceQuestionValueChange = (
    updatedMultiChoiceOptions: MultipleChoiceOptionNumericId[]
  ) => {
    const returnValue =
      multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
        updatedMultiChoiceOptions,
        question
      );

    onValueChange(returnValue, "MULTICHOICE", question.formId);
  };

  /* ********************************************** State Changes ********************************************** */

  const onTextValueChange = (newValue: QuestionAnswerValue) => {
    onValueChange(newValue, "TEXT", question.formId);
  };

  const onBehaviourValueChange = (newValue: QuestionAnswerValue) => {
    onValueChange(newValue, "BEHAVIOUR", question.formId);
  };

  const onDevelopmentValueChange = (newValue: QuestionAnswerValue) => {
    // Change the state
    onValueChange(newValue, "DEVELOPMENT-LIST", question.formId);
  };

  const onGoalReviewValueChange = (newValue: QuestionAnswerValue) => {
    onValueChange(newValue, "GOAL-REVIEW", question.formId);
  };

  const handleChangeAdvancedTasks = (
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[],
    newTasks?: AdvancedTaskDto[]
  ) => {
    onChangeAdvancedTasks(question.questionId, tasks, cancelledTasks, newTasks);
  };

  /* ********************************************** End State Changes ********************************************** */

  /* ********************************************** Answer Saving ********************************************** */

  const runPreSaveStateChanges = () => {
    setIsSaving(true);
    setSavingSucceeded(false);
    setAnswerSavingErrored(false);
    setTasksSavingErrored(false);
    setEnforcedCommentSavingErrorStatus({
      errored: false,
      behaviourId: null,
      goalId: null,
      nonStateValue: null,
    });
  };

  const answerSaveSuccessCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(true);
  };

  const answerSaveErrorCallback = () => {
    setIsSaving(false);
    setAnswerSavingErrored(true);
  };

  const tasksSaveSuccessCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(true);
  };

  const tasksSaveErrorCallback = () => {
    setIsSaving(false);
    setTasksSavingErrored(true);
  };

  const enforcedCommentSaveSuccessCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(true);
    setEnforcedCommentSavingErrorStatus({
      errored: false,
      behaviourId: null,
      goalId: null,
      nonStateValue: null,
    });
  };

  const enforcedCommentSaveErrorCallback = (
    behaviourId: number | null,
    goalId: number | null
  ) => {
    setIsSaving(false);
    setSavingSucceeded(false);
    setEnforcedCommentSavingErrorStatus({
      errored: true,
      behaviourId: behaviourId,
      goalId: goalId,
      nonStateValue: null,
    });
  };

  const getQuestionAnswerObject = (
    answerValue: QuestionAnswerValue
  ): QuestionAnswer | undefined => {
    const answerTimestamp = dateHelper.getCurrentDateUtc();
    const answerType = questionTypeHelper.getAnswerTypeForQuestionType(
      question.questionType
    );

    if (!answerType) return undefined;

    return {
      id: null,
      questionId: question.questionId,
      answer: answerValue,
      timestamp: answerTimestamp,
      userId: userContext.user.id,
      answerType: answerType,
      formId: question.formId,
    };
  };

  /** Call the API to save the answer */
  const handleValueSave = useCallback(
    (answerValue: QuestionAnswerValue) => {
      if (answerValue !== null && answerValue !== undefined) {
        runPreSaveStateChanges();

        const questionAnswer = getQuestionAnswerObject(answerValue);
        if (questionAnswer === undefined) {
          answerSaveErrorCallback();
        } else {
          onValueSave(
            questionAnswer,
            answerSaveSuccessCallback,
            answerSaveErrorCallback
          );
        }
      }
    },
    [question, currentValue, getQuestionAnswerObject, onValueSave]
  );

  /** Call the API to save the answer for multiple choice questions */
  const handleValueSaveMulti = useCallback(
    (answerValue: MultipleChoiceOptionNumericId[]) => {
      if (answerValue !== null && answerValue !== undefined) {
        runPreSaveStateChanges();
        const latestAnswer =
          multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
            answerValue,
            question
          );
        const questionAnswer = getQuestionAnswerObject(latestAnswer);
        if (questionAnswer === undefined) {
          answerSaveErrorCallback();
        } else {
          onValueSave(
            questionAnswer,
            answerSaveSuccessCallback,
            answerSaveErrorCallback
          );
        }
      }
    },
    [question, currentValue, getQuestionAnswerObject, onValueSave]
  );

  const handleGoalReviewValueSave = (answerValue: QuestionAnswerValue) => {
    // Save the value and ensure goal setting goals are kept in sync
    handleValueSave(answerValue);
  };

  const handleRetrySave = useCallback(() => {
    if (tasksSavingErrored) {
      // Retry saving tasks
      runPreSaveStateChanges();
      onRetryTasksSave(
        question.questionId,
        tasksSaveSuccessCallback,
        tasksSaveErrorCallback
      );
    }

    if (answerSavingErrored) {
      // Retry saving answers
      runPreSaveStateChanges();
      onRetryValueSave(
        question.questionId,
        answerSaveSuccessCallback,
        answerSaveErrorCallback
      );
    }

    if (enforcedCommentSavingErrorStatus.errored) {
      // Retry saving enforced comments
      runPreSaveStateChanges();
      onRetryEnforcedCommentSave(
        question.questionId,
        enforcedCommentSavingErrorStatus.behaviourId,
        enforcedCommentSavingErrorStatus.goalId,
        question.formId,
        answerSaveSuccessCallback,
        () => {
          // Clear the saving flags, but leave
          // `enforcedCommentSavingErrorStatus` as it is
          setIsSaving(false);
          setSavingSucceeded(false);
        }
      );
    }
  }, [
    question,
    currentValue,
    tasks,
    comments,
    onRetryTasksSave,
    onRetryValueSave,
    onRetryEnforcedCommentSave,
    tasksSavingErrored,
    answerSavingErrored,
    enforcedCommentSavingErrorStatus,
  ]);

  const handleBehaviourEnforcedCommentChange = useCallback(
    (newValue: string | null, behaviourId: number, clientFormId: number) => {
      onEnforcedCommentChange(newValue, "BEHAVIOUR", behaviourId, clientFormId);
    },
    [question, onEnforcedCommentChange]
  );

  const handleGoalEnforcedCommentChange = useCallback(
    (goalId: number, newValue: string | null, clientFormId: number) => {
      onEnforcedCommentChange(newValue, "GOAL", goalId, clientFormId);
    },
    [question, onEnforcedCommentChange]
  );

  const handleBehaviourEnforcedCommentBlur = useCallback(
    (behaviourId: number) => {
      runPreSaveStateChanges();
      onEnforcedCommentBlur(
        question.questionId,
        behaviourId,
        null,
        question.formId,
        enforcedCommentSaveSuccessCallback,
        () => enforcedCommentSaveErrorCallback(behaviourId, null)
      );
    },
    [question, currentValue, comments, onEnforcedCommentBlur]
  );

  const handleGoalEnforcedCommentBlur = useCallback(
    (goalId: number, newValue: string | null) => {
      runPreSaveStateChanges();
      onEnforcedCommentBlur(
        question.questionId,
        null,
        goalId,
        question.formId,
        enforcedCommentSaveSuccessCallback,
        () => enforcedCommentSaveErrorCallback(null, goalId)
      );
    },
    [question, currentValue, comments, onEnforcedCommentBlur]
  );

  const handleTaskChangeAndSave = useCallback(
    (tasks: QuestionTasks) => {
      runPreSaveStateChanges();
      onChangeQuestionTasks(
        tasks,
        tasksSaveSuccessCallback,
        tasksSaveErrorCallback
      );
    },
    [question, currentValue, tasks]
  );

  /* ********************************************** End Answer Saving ********************************************** */

  const allowEnterPressToMoveToNextQuestion =
    question.questionType !== "LONGTEXT" &&
    question.questionType !== "BEHAVIOUR" &&
    question.questionType !== "ADVANCED-TASK-MANAGEMENT";

  const handleEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key === "Enter" &&
      enableNextButton &&
      allowEnterPressToMoveToNextQuestion
    ) {
      onNextPageClick();
    }
  };

  const questionDisplayText = questionTextHelper.getQuestionText(
    question,
    subjectUser,
    answerSetParticipants,
    userContext
  );

  const singleStandardComment =
    comments && comments.length === 1 ? comments[0] : undefined;

  let questionComponent = null;
  let doesQuestionTypeAllowPagerVisibility = true;
  switch (question.questionType) {
    case "SLIDER":
      const sliderScoreDisplayMode =
        multipleChoiceQuestionHelper.getSliderScoreDisplayMode(
          multipleChoiceQuestionOptions
        );
      questionComponent = (
        <Slider
          scaleOptions={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          selectedTrackBgColourClassName="bg-white"
          emptyTrackBgColourClassName="bg-white/40"
          selectedValueDisplayMode={sliderScoreDisplayMode}
          onBlur={handleValueSaveMulti}
        />
      );
      break;
    case "RADIOLIST":
      questionComponent = (
        <RadioButtonGroup
          uniqueFieldName={`radio_q_${question.questionId}`}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          labelClassNames="text-white"
          onBlur={handleValueSaveMulti}
        />
      );
      break;
    case "CHECKLIST":
      const checkboxCount = question.multiChoiceOptions!.length;
      // Change the display mode based on how many checkboxes there are to show
      let displayMode: CheckBoxListDisplayMode = "horizontal";
      if (checkboxCount > 10) {
        displayMode = "grid";
      } else if (checkboxCount > 2) {
        displayMode = "vertical";
      }

      questionComponent = (
        <CheckBoxList
          uniqueFieldName={`checklist_q_${question.questionId}`}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          displayMode={displayMode}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          textColourClassName="text-white"
          iconColourClassName="text-white"
          checkboxBorderClassName="border border-white/40"
          selectMinCount={question.validation.min}
          selectMaxCount={question.validation.max}
          onBlur={handleValueSaveMulti}
        />
      );
      break;
    case "LONGTEXT":
      questionComponent = (
        <TextArea
          inputId={inputIdAttributeValue}
          value={currentValue as string}
          onChange={onTextValueChange}
          minRows={3}
          maxRows={6}
          placeholder={
            question.placeholderText
              ? t(question.placeholderText)
              : t("Common.TextboxDefaultPlaceholder")
          }
          className="mt-2 p-2 w-full rounded-md bg-white/20 border-0 placeholder:text-white/40 placeholder:text-sm focus:outline-none focus:ring-0"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          onBlur={handleValueSave}
        />
      );
      break;
    case "SHORTTEXT":
      questionComponent = (
        <TextInput
          inputId={inputIdAttributeValue}
          value={currentValue as string}
          onChange={onTextValueChange}
          placeholder={
            question.placeholderText
              ? t(question.placeholderText)
              : t("Common.TextboxDefaultPlaceholder")
          }
          className="mt-2 p-2 w-full rounded-md bg-white/20 border-0 placeholder:text-white/40 placeholder:text-sm focus:outline-none focus:ring-0"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          onBlur={handleValueSave}
        />
      );
      break;
    case "DROPDOWNLIST":
      questionComponent = (
        <FormDropDownList<MultipleChoiceOptionNumericId>
          inputId={inputIdAttributeValue}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          className="block my-3 w-full rounded-md bg-white/20 border-0 focus:outline-none focus:ring-0"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          onBlur={handleValueSaveMulti}
        />
      );
      break;
    case "BEHAVIOUR":
      doesQuestionTypeAllowPagerVisibility = false;

      if (
        question.behaviourOptionsArray &&
        question.behaviourOptionsArray.length > 0
      ) {
        // Get some settings from the first element in the collection
        // (they should all be the same anyway)
        const showAddTaskButton =
          !isInPrepMode &&
          !usesAdvancedTaskTypes &&
          question.behaviourOptionsArray[0].showAddTaskButton;
        const sliderScoreDisplayType =
          question.behaviourOptionsArray[0].sliderScoreDisplayType;
        const enforcedComments = comments.filter(
          (x) => x.commentType === "ENFORCED" && x.behaviourId !== null
        );
        questionComponent = (
          <MultipleBehaviourQuestion
            question={question}
            participants={answerSetParticipants}
            formId={question.formId}
            formType="JOURNEY"
            behaviourOptions={question.behaviourOptionsArray!}
            showAddTaskButton={showAddTaskButton}
            addTaskButtonClassName="journey-btn-primary"
            showValidationErrors={showValidationErrors}
            onValueChange={onBehaviourValueChange}
            onBlur={handleValueSave}
            currentValues={currentValue as BehaviourQuestionAnswerValue[]}
            selectedTrackBgColourClassName="bg-white"
            emptyTrackBgColourClassName="bg-white/40"
            formBackgroundColorStyle="ffffff"
            subjectUser={subjectUser}
            sliderScoreDisplayType={sliderScoreDisplayType}
            isReadOnly={false}
            tasks={tasks}
            onChangeQuestionTasks={handleTaskChangeAndSave}
            enforcedCommentIsOptional={question.enforcedCommentIsOptional}
            enforcedCommentQuestionText={question.enforcedCommentQuestionText}
            enforcedComments={enforcedComments}
            onEnforcedCommentChange={handleBehaviourEnforcedCommentChange}
            onEnforcedCommentBlur={handleBehaviourEnforcedCommentBlur}
            showPlanningResponses={null /* Not supported in journeys */}
            planningResponseParticipants={null /* Not supported in journeys */}
          />
        );
      } else {
        const enforcedComment = comments.find(
          (x) =>
            x.commentType === "ENFORCED" &&
            x.behaviourId === question.behaviourOptions!.behaviour!.key
        );
        questionComponent = (
          <BehaviourAttributeQuestion
            question={question}
            formId={question.formId}
            formType="JOURNEY"
            attributes={question.behaviourOptions!.attributes}
            behaviour={question.behaviourOptions!.behaviour}
            scalePoints={question.behaviourOptions!.scale}
            singleScaleLabel={question.behaviourOptions!.singleScaleLabel}
            showAddTaskButton={
              !isInPrepMode &&
              !usesAdvancedTaskTypes &&
              question.behaviourOptions!.showAddTaskButton
            }
            showValidationErrors={showValidationErrors}
            onValueChange={onBehaviourValueChange}
            onBlur={handleValueSave}
            currentValues={currentValue as BehaviourQuestionAnswerValue[]}
            selectedTrackBgColourClassName="bg-white"
            emptyTrackBgColourClassName="bg-white/40"
            formBackgroundColorStyle="ffffff"
            subjectUser={subjectUser}
            addTaskButtonClassName="journey-btn-primary"
            sliderScoreDisplayType={
              question.behaviourOptions!.sliderScoreDisplayType
            }
            isReadOnly={false}
            tasks={tasks}
            onChangeQuestionTasks={handleTaskChangeAndSave}
            enforcedCommentIsOptional={question.enforcedCommentIsOptional}
            enforcedCommentQuestionText={question.enforcedCommentQuestionText}
            enforcedComment={enforcedComment}
            onEnforcedCommentChange={handleBehaviourEnforcedCommentChange}
            onEnforcedCommentBlur={handleBehaviourEnforcedCommentBlur}
            participants={questionParticipants}
            showPlanningResponses={null /* Not supported in journeys */}
          />
        );
      }

      break;
    case "LEARNING-AND-DEVELOPMENT":
      questionComponent = (
        <DevelopmentQuestion
          formId={question.formId}
          formType="JOURNEY"
          question={question}
          isReadOnly={false}
          tasks={tasks}
          usesAdvancedTaskTypes={userContext.user.client.taskTypes.length > 0}
          onChangeQuestionTasks={handleTaskChangeAndSave}
          currentValues={currentValue as DevelopmentQuestionAnswerValue[]}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          onValueChange={onDevelopmentValueChange}
          onBlur={handleValueSave}
        />
      );
      break;
    case "GOAL-SETTING":
      questionComponent = (
        <GoalSettingQuestion
          formId={question.formId}
          question={question}
          isReadOnly={false}
          addTaskButtonClassName="journey-btn-primary"
          showValidationErrors={showValidationErrors}
          tasks={tasks}
          onChangeQuestionTasks={handleTaskChangeAndSave}
          formType="JOURNEY"
          separateRolledOverGoals={
            formComplexities?.goalRolloverConfig?.validConfig === true
          }
        />
      );
      break;
    case "GOAL-REVIEW-STATUS-COMMENT":
      const enforcedComments = comments.filter(
        (x) => x.commentType === "ENFORCED" && x.goalId !== null
      );
      questionComponent = (
        <GoalReviewQuestionStatusAndComment
          formType="JOURNEY"
          question={question}
          currentValues={currentValue as GoalReviewQuestionAnswerValue[]}
          isReadOnly={false}
          showValidationErrors={false}
          subjectUser={subjectUser}
          onValueChange={onGoalReviewValueChange}
          onValueSave={handleGoalReviewValueSave}
          enforcedComments={enforcedComments}
          onEnforcedCommentChange={handleGoalEnforcedCommentChange}
          onEnforcedCommentBlur={handleGoalEnforcedCommentBlur}
          showPlanningResponses={null /* Not supported in journeys */}
          isPrinting={false /* Not supported in journeys */}
        />
      );
      break;
    case "ADDTASK":
      questionComponent = (
        <TaskQuestion
          formId={question.formId}
          question={question}
          isReadOnly={false}
          tasks={tasks}
          onChangeQuestionTasks={handleTaskChangeAndSave}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          formType="JOURNEY"
        />
      );
      break;
    case "READONLY":
      doesQuestionTypeAllowPagerVisibility = false;
      break;
    case "ADVANCED-TASK-MANAGEMENT":
      questionComponent =
        question.taskManagementConfig != null ? (
          <TaskManagementQuestion
            tasks={question.taskManagementConfig.tasks}
            questionValidations={question.validation}
            cancelledTasks={question.taskManagementConfig.cancelledTasks}
            newTasks={question.taskManagementConfig.newTasks}
            liveAndNewTasks={question.taskManagementConfig.liveAndNewTasks}
            formType="JOURNEY"
            formId={question.formId}
            subjectUser={subjectUser}
            config={question.taskManagementConfig}
            isReadOnly={false}
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            answerSetUniqueId={answerSetUniqueId}
            answerSetDateCreated={answerSetDateCreated}
            participants={questionParticipants}
            onPreTaskInteractionCheckForAnswerSet={
              onPreAdvancedTaskInteractionCheckForAnswerSet
            }
            onChangeAdvancedTasks={handleChangeAdvancedTasks}
          />
        ) : null;
      break;
    default:
      questionComponent = null;
      break;
  }

  const questionLabelParts = questionTextHelper.getQuestionTextParts(
    questionDisplayText,
    "JOURNEY"
  );

  /** If there's a header to render, this will be the title element */
  const questionHeader = questionLabelParts.header ? (
    <div className="text-xl mb-4 font-semibold">
      {questionLabelParts.header}
    </div>
  ) : null;

  return (
    <div className="w-full grow flex flex-col justify-center text-white pb-4">
      <div className="question" onKeyPress={handleEnterPress}>
        {questionHeader}
        {question.questionType === "READONLY" && (
          <>
            <SafeRenderHtml
              htmlText={questionLabelParts.label}
              containerClassName="my-1 px-2 journey-custom-content text-lg"
              otherChildren={
                question.helpText ? (
                  <MoreInfo
                    title={
                      question.helpTextTrigger ??
                      t("Forms.Generic.MoreInfoButtonText")
                    }
                    triggerText={question.helpTextTrigger}
                    content={question.helpText}
                    formType="JOURNEY"
                  />
                ) : undefined
              }
            />
          </>
        )}
        {question.questionType !== "READONLY" && (
          <>
            <div className="mb-3 font-medium tracking-[.015em] items-start">
              <Label
                text={questionLabelParts.label}
                htmlFor={inputIdAttributeValue}
                className="text-xl opacity-90"
              />
              {(!question.validation.required ||
                question.helpText ||
                question.behaviourOptions?.behaviour.key) && (
                <span className="pl-1">
                  {!question.validation.required && (
                    <Badge text={t("Common.Optional")} marginClassName="mx-1" />
                  )}
                  {question.helpText && (
                    <MoreInfo
                      title={questionLabelParts.label}
                      triggerText={question.helpTextTrigger}
                      content={question.helpText}
                      formType="JOURNEY"
                    />
                  )}
                  {question.behaviourOptions?.behaviour.key && (
                    <BehaviourMoreInfo
                      behaviour={question.behaviourOptions!.behaviour}
                      infoTooltipContent={
                        question.behaviourOptions!.infoTooltipContent
                      }
                      formType="JOURNEY"
                    />
                  )}
                </span>
              )}
            </div>
            {question.behaviourOptions?.behaviour.key && (
              <>
                <BehaviourSubtext
                  behaviour={question.behaviourOptions!.behaviour}
                  subtextContent={question.behaviourOptions!.subtextContent}
                  classNames="text-white/80"
                />
              </>
            )}
            {questionComponent}
            <JourneyAnswerSaveIndicator
              didSave={savingSucceeded}
              errored={
                answerSavingErrored ||
                tasksSavingErrored ||
                enforcedCommentSavingErrorStatus.errored
              }
              isSaving={isSaving}
              retrySave={handleRetrySave}
            />
          </>
        )}
      </div>
      <div className="flex flex-row justify-end space-x-4 w-full">
        {question.commentsEnabled &&
          question.questionType !== "LONGTEXT" &&
          question.questionType !== "READONLY" && (
            <JourneyCommentControl
              currentValue={singleStandardComment?.comment}
              onChange={onCommentChange}
              questionId={question.questionId}
            />
          )}
        {/* Removed the below temporarily as part of TR-215. This will be re-addressed in UX optimisation for speedy users at a later date */}
        {/* <div className="hidden lg:block">
          <JourneyKeyboardPager
            enableNextButton={enableNextButton}
            onNextPageClick={onNextPageClick}
            showSubmitBtn={showSubmitBtn}
            showEnterPressInstructions={allowEnterPressToMoveToNextQuestion}
            questionTypeAllowsVisibility={doesQuestionTypeAllowPagerVisibility}
          />
        </div> */}
      </div>
    </div>
  );
}

export default JourneyQuestion;
