import TextareaAutosize from "react-textarea-autosize";
import cx from "classnames";
import { QuestionAnswer, ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import React from "react";

interface TextAreaProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
  /** An event to call when the input loses focus */
  onBlur?(
    latestValue: string,
    nonStateValue?: QuestionAnswer | null
  ): void | undefined;
  /** The current value for the textarea */
  value: string | undefined;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The minimum number of rows to have at all times */
  minRows?: number | undefined;
  /** The maximum number of rows to have before the scrollbar appears */
  maxRows?: number | undefined;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
  maxLength?: number | undefined;

  /** Choose whether you want the readonly display mode to be a disabled input, or a more friendly paragraph of text on the page */
  readonlyDisplayMode?: "DISABLED-INPUT" | "TEXT";

  /** If the `readonlyDisplayMode` is set to "TEXT" and there is no value,
   * this text will be displayed instead
   */
  readonlyTextModeEmptyPlaceholder?: string | undefined;

  /** If the `readonlyDisplayMode` is set to "TEXT", you can pass in custom class names to style the text */
  readonlyTextModeCustomClassNames?: string | undefined;
}

/** A TextArea element which can auto-grow up to a maximum number of rows
 * to avoid the user having to scroll as soon as the text gets quite long
 */
const TextArea = ({
  onChange,
  onBlur = undefined,
  minRows,
  maxRows,
  showValidationErrors = false,
  validationResult = null,
  value = "",
  inputId = "",
  className = "rounded-md border-0 border-none",
  placeholder = undefined,
  inputRef = undefined,
  isReadOnly = false,
  maxLength = undefined,
  readonlyDisplayMode = "DISABLED-INPUT",
  readonlyTextModeEmptyPlaceholder = undefined,
  readonlyTextModeCustomClassNames = undefined,
}: TextAreaProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(e.target.value);
    }
  };

  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div className={cx("hidden print:block print-avoid-break", className)}>
        {value ? value : ""}
      </div>
      {isReadOnly && readonlyDisplayMode === "TEXT" && (
        <ReadonlyDisplayText
          fieldValue={value}
          placeholder={readonlyTextModeEmptyPlaceholder}
          customClassNames={readonlyTextModeCustomClassNames}
        />
      )}
      {(!isReadOnly || readonlyDisplayMode === "DISABLED-INPUT") && (
        <TextareaAutosize
          id={inputId}
          onChange={handleChange}
          value={value ? value : ""}
          className={cx(
            "focus:outline-none focus:ring-0 disabled:text-gray-600",
            className,
            isReadOnly ? "cursor-not-allowed" : "",
            "print:hidden"
          )}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          ref={inputRef}
          disabled={isReadOnly}
          onBlur={handleBlur}
          maxLength={maxLength}
        />
      )}
    </div>
  );
};

/** A component for rendering a textarea as readonly text on the page instead, i.e. not a disabled input */
const ReadonlyDisplayText = ({
  fieldValue,
  placeholder,
  customClassNames,
}: {
  fieldValue: string | null | undefined;
  placeholder: string | undefined;
  customClassNames: string | undefined;
}) => {
  // Use the field value if there is one, otherwise use the placeholder
  let styleAsPlaceholder = false;
  let displayText = fieldValue;
  if (!displayText || displayText.trim() === "") {
    displayText = placeholder;
    styleAsPlaceholder = true;
  }

  if (!displayText) return null;

  // Replace new line characters with <br> tags
  displayText = displayText.replace(/\n/g, "<br>");

  return (
    <div
      className={cx(
        "print:hidden",
        customClassNames,
        styleAsPlaceholder ? "italic" : ""
      )}
    >
      {displayText}
    </div>
  );
};

export default TextArea;
